import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TabContent, TabPane, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  dispatchQuickQuote,
  dispatchMotorQuote,
  dispatchResetQuickQuote,
  generateArrayOfYears,
  generateArrayOfYearsForNew,
  resetQuickQuoteResults,
  saveUserDetails,
  quickQuoteResult,
  saveApiErrorReponse,
} from "../../../store/actions/userActions";
import { policyResultArr, policyResultArrNew } from "./MakeModelArr";
import store from "../../../store";
import { motorQuotationObj } from "../../../store/reducers/userReducers";
import SelectList from "../Tags/SelectList";
import { POLICY_TYPE, POLICY_STATUS, CUSTOMER_TYPE, CUSTOMER_TYPE_INDIVIDUAL, } from "../../utility/constants";
import {
  getBajajMMV,
  getVehicleMake,
  getVehicleModel,
  getVehiclePincode,
  getVehiclePreviousInsurer,
  getVehicleRto,
  getVehicleVariant,
} from "../../Services/masterServices";
import RadioBox from "../Tags/RadioBox";
import ReactSelect from "../Tags/ReactSelect";
import {
  bikePolicyInfoArr,
  fourWheelerModel,
  gcvVehiclePolicyInfoArr,
  pcvVehiclePolicyInfoArr,
  pvtCarPolicyInfoArr,
  twoWheelerMake,
} from "./MakeModelArr";
import InputBox from "../Tags/InputBox";
import {
  bajajApiCall,
  createCustomer,
  digitAPICall,
  getMotorQuote,
  FutureGeneralQuickQuote,
  getLibertyAPICall,
  getZunoQuickQuote,
  HDFCCalculatePremium,
  KotakAPICall,
  RelianceAPICall,
  RoyalSundramAPICall,
  savePolicyToDatabase,
  shriramGenerateProposal,
  magmaAPICall,
  processFlow,
} from "./TPApiCall";
import { useRef } from "react";
import { useState } from "react";
import moment from "moment";
import QuotePolicyDetails from "./QuotePolicyDetails";
import Header from "../Header";
import WhyExpertCoverBuy from "../WhyExpertCoverBuy";
import CarInsuranceDetails from "../CarInsuranceDetails";
import BikeInsuranceDetails from "../BikeInsuranceDetails";
import TaxiInsuranceDetails from "../TaxiInsuranceDetails";
import Footer from "../Footer";
import { event } from "jquery";
import { flow } from "lodash";

import { GetDataWithToken } from "../../../api/apiHelper";

const HomeSection = () => {
  const [parsedData, setParsedData] = useState({});
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const userDetails = useSelector((state) => state.root.userDetails);
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const location = useLocation();
  console.log("Lcoation Data", parsedData);
  const vTypes = location.state ? location?.state : parsedData;
  console.log("vTypes", vTypes);
  let { vehicleModal, vehicleType } = vTypes;
  console.log("Home Section", vehicleModal, vehicleType);
  const [activeStep, setActiveStep] = useState(1);
  const [vehicleModel, setVehicleModel] = useState("");
  const [quickPick, setQuickPick] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [makeData, setMakeData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [variantData, setVariantData] = useState([]);
  const [insurerData, setInsurerData] = useState([]);
  const [rtoData, setRtoData] = useState([]);
  const [RegistrationNumber, setRegistrationNumner] = useState("");
  const [isNewVehicle, setIsNewVehicle] = useState(false);
  const [regYear, setRegYear] = useState("");
  const [policyInputs, setPolicyInputs] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [showSecondComponent, setShowSecondComponent] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState(false);
  const [tncError, setTncError] = useState(false);
  const [formsError, setFormsError] = useState("");
  const [selectValueError, setSelectedValueError] = useState(false);
  const [initialMinIDV, setInitialMinIDV] = useState(null);
  const [initialMaxIDV, setInitialMaxIDV] = useState(null);
  const [getMotorQuoteResponse, setGetMotorQuoteResponse] = useState(null);
  const [dataMotorQuotation, setDataMotorQuotation] = useState({});

  const searchParams = new URLSearchParams(window.location.search);
  const posId = searchParams.get("posId");
  const vehicleTypeFromPos = searchParams.get("vehicleType");
  console.log("searchParams", searchParams, posId, vehicleTypeFromPos);
  const dispatch = useDispatch();

  const flowApi = async ()=>{

    // debugger

    try {
      const response = await processFlow();
      console.log("RESPONSE", response);
      
      dispatchMotorQuote(
        "customerLeadId",
        response?.data?.customerLeadId
      );
    } catch (error) {
      console.error("Error in flowApi:", error);
    }

  }

  function getDataFromCookie(cookieName) {
    // Split cookies by semicolon
    const cookies = document.cookie.split(";");

    // Iterate over cookies to find the one with the specified name
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if the cookie starts with the specified name
      if (cookie.startsWith(cookieName + "=")) {
        // Return the value of the cookie
        return decodeURIComponent(cookie.substring(cookieName.length + 1));
      }
    }
    // Return null if the cookie is not found
    return null;
  }

  useEffect(() => {
    const myData = getDataFromCookie("Data");
    if (myData) {
      // Parse the retrieved data if needed
      setParsedData(JSON.parse(myData));
      console.log("Parsed Data", parsedData);
    } else {
      console.log('Cookie "myData" not found');
    }
  }, []);

  useEffect(() => {
    if (apiRequestQQ?.posId === "") {
      dispatchQuickQuote("posId", userDetails?.username);
      dispatchMotorQuote("posId", userDetails?.username);
    }
    if (vehicleTypeFromPos && posId) {
      vTypes.vehicleType = vehicleTypeFromPos;
      if (vehicleTypeFromPos === "4w") {
        vTypes.vehicleModal = "4w";
      } else if (vehicleTypeFromPos === "2w") {
        vTypes.vehicleModal = "2w";
      } else if (vehicleTypeFromPos === "gcv") {
        vTypes.vehicleModal = "gcv";
      } else if (vehicleTypeFromPos === "pcv") {
        vTypes.vehicleModal = "pcv";
      }
      if (posId) {
        GetDataWithToken(`admin/getUserById?userId=${posId}`).then((res) => {
          console.log("response getUserById", res);
          document.cookie = `Data=${encodeURIComponent(
            JSON.stringify(vTypes)
          )}; domain=.expertcover.in`;
          document.cookie = `posId=${posId}; domain=.expertcover.in`;
          document.cookie = `userDetails=${encodeURIComponent(
            JSON.stringify(res?.data)
          )}; domain=.expertcover.in`;
          dispatch(saveUserDetails(res?.data));
          dispatchQuickQuote("posId", posId);
          dispatchMotorQuote("posId", posId);
        });
      }
    }
  }, []);

  useEffect(() => {
    getVehicleRto().then((response) => {
      console.log(response, "response");
      if (response.status === true) {
        let data = response?.data;
        let i = 0;
        let arr = [];
        while (i < data?.length) {
          let item = data[i];
          arr.push({
            label: item.registered_city_name + " (" + item.RTO_Code + ")",
            value: item.RTO_Code,
            registered_state_name: item.registered_state_name,
            id: item.id,
            registered_city_name: item.registered_city_name,
          });
          i++;
        }
        setRtoData(arr);
      }
    });
    getVehiclePreviousInsurer().then((response) => {
      if (response.status === true) {
        let i = 0;
        let data = response.data;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            option: item.Name,
            value: item.Digit_Code,
            prevInsurerId: item?.prevInsurerId,
          });
          i++;
        }
        setInsurerData(arr);
      }
    });
  }, []);

  useEffect(() => {
    if (vehicleModal) {
      setVehicleType();
    }
  }, [vehicleModal]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    console.log("VehicleModledddddd", vehicleModal);
  }, []);
  const toggleStep = (step) => {
    setActiveStep(step);
  };


  const motorQuotation = async (data) => {  
    // debugger;
    if (data) {
      let response;
      let isEqual;
      console.log("new Dataa", data);
      console.log("previous Data", dataMotorQuotation);
      let newData = { ...data };
      for (let key in data) {
        console.log("comparison", data[key], dataMotorQuotation[key]);
        if (data[key] !== dataMotorQuotation[key]) {
          isEqual = false;
          break;
        } else {
          isEqual = true;
        }
      }
      // let isEqual = compareObjects(data, dataMotorQuotation);
      console.log("isEqual");
      if (!isEqual) {
        dispatchMotorQuote("rId", "");
        newData = { ...newData, rId: "" };
      }
      console.log("isEqual", isEqual);
      setDataMotorQuotation(newData);
      let updatedData = { ...newData };
      let executionCount = 0;
      const fetchQuotesData = async () => {
        executionCount++;
        if (
          updatedData?.rId &&
          response?.caseStatus === "Quotes Generated" &&
          response?.isAllQuotesDone
        ) {
          // debugger;
          // response = await getMotorQuote(updatedData);
          getMotorQuote(updatedData).then((response) => {
            // debugger
            console.log("response", response);
            setGetMotorQuoteResponse(response);
            dispatchMotorQuote('sessionId', response?.sessionId)
            console.log("executionCount", executionCount);
            if (executionCount > 5) {

              dispatchMotorQuote('caseId',response.caseId)
              dispatchMotorQuote('planId',response.quotes[0] ? response.quotes[0].planId  : '' )
              dispatchMotorQuote('insurerId' , response.quotes[0]  ? response.quotes[0].insurerId : '')


              clearInterval(interval);
              handleQuotesData(response);
            } 
          })
         
          if (response?.quotes?.length == 0) {
            // saveApiErrorReponse(
            //   "Tata",
            //   '',
            //   "/assetss/images/TataLogo.png"
            // );
            clearInterval(interval);
          }
          console.log("response generated");
        } else if (
          updatedData?.rId &&
          response?.caseStatus === "Quotes Requested"
        ) {
          response = await getMotorQuote(updatedData);
          console.log("response requested", response);
          setGetMotorQuoteResponse(response);
          dispatchMotorQuote('sessionId', response?.sessionId)

          updatedData = { ...updatedData, rId: response?.rId };
          dispatchMotorQuote("rId", response?.rId);
        } else if (
          updatedData?.rId &&
          response?.caseStatus === "Quotes In Process" &&
          response?.isAllQuotesDone === false

        ) {
          getMotorQuote(updatedData).then((response) => {
            // debugger
            console.log("response", response);
            // setGetMotorQuoteResponse(response);
            console.log("executionCount", executionCount);
            if (executionCount > 8) {

              dispatchMotorQuote('caseId',response.caseId)
              dispatchMotorQuote('planId',response.quotes[0] ?  response.quotes[0].planId : '')
              dispatchMotorQuote('insurerId' ,response.quotes[0] ?  response.quotes[0].insurerId : '')


              clearInterval(interval);
              handleQuotesData(response);
            }
          }
        )
        }
        else {
          if(executionCount < 6){

            response = await getMotorQuote(updatedData);
            console.log("response initiated", response);
            setGetMotorQuoteResponse(response);
            dispatchMotorQuote('sessionId', response?.sessionId)
            updatedData = { ...updatedData, rId: response?.rId };
            dispatchMotorQuote("rId", response?.rId);

            

          }

          else{

          

          clearInterval(interval);
              handleQuotesData(response);
          }
         
        }
      };

      const interval = setInterval(fetchQuotesData, 2000);
    }
  };

  const compareObjects = (prevObj, newObj) => {
    // debugger;
    console.log("Object 1", prevObj);
    console.log("Object 1", newObj);
    for (let key in prevObj) {
      console.log("comparison", prevObj[key], newObj[key]);
      if (prevObj[key] !== newObj[key]) {
        return false;
      }
    }
    return true;
  };

  const handleQuotesData = (data) => {
    // debugger;
    data?.quotes?.map((item) =>
      store.dispatch(
          quickQuoteResult(
            policyResultArrNew(
              item?.insurer === 'ICICI Lombard General Insurance Co. Ltd.' && "/assetss/images/icici.jpeg" || item?.insurer === "Tata AIG General Insurance Co. Ltd." &&  "/assetss/images/TataLogo.png" || item?.insurer === 'Bajaj Allianz General Insurance Co. Ltd.' && "/assets/images/logos/partners/bajaj.png"  || item?.insurer === 'HDFC' && "/assets/images/hdfcegro.jpeg" || item?.insurer === 'Zurich Kotak General Insurance Company (India) Limited' && '/assetss/images/ZK Horizontal.png' || item?.insurer === 'Future Generali India Insurance Company Limited' && '/assets/images/furturegeneral.png',
              item?.insurer,
              "",
              "",
              motorRequest?.registrationNumber,
              item?.make,
              item?.model,
              item?.variant,
              item?.basicODPremium,
              item?.basicTPPremium,
              item?.netPremium,
              item?.gst,
              item?.finalPremium,
              item?.minIdv,
              item?.maxIdv,
              item?.idv,
              item?.policyStartDate,
              item?.policyEndDate,
              item?.discount,
              item?.insurer,
              item?.policyType,
              item?.roadSideAssistance?.isOpted,
              item?.roadSideAssistance?.isOpted
                ? item?.roadSideAssistance?.premium
                : item?.roadSideAssistance?.isOpted,
              item?.engineProtection?.isOpted,
              item?.engineProtection?.isOpted
                ? item?.engineProtection?.premium
                : item?.engineProtection?.isOpted,
              item?.tyreProtection?.isOpted,
              item?.tyreProtection?.isOpted
                ? item?.tyreProtection?.premium
                : item?.tyreProtection?.isOpted,
              item?.rimProtection?.isOpted,
              item?.rimProtection?.isOpted
                ? item?.rimProtection?.premium
                : item?.rimProtection?.isOpted,
              item?.consumables?.isOpted,
              item?.consumables?.isOpted
                ? item?.consumables?.premium
                : item?.consumables?.isOpted,
              item?.electricalAccessories?.isOpted,
              item?.electricalAccessories?.isOpted
                ? item?.electricalAccessories?.premium
                : item?.electricalAccessories?.isOpted,
              item?.nonElectricalAccessories?.isOpted,
              item?.nonElectricalAccessories?.isOpted
                ? item?.nonElectricalAccessories?.premium
                : item?.nonElectricalAccessories?.isOpted,
              item?.invoiceCover?.isOpted,
              item?.invoiceCover?.isOpted
                ? item?.invoiceCover?.premium
                : item?.invoiceCover?.isOpted,
              item?.ncbProtection?.isOpted,
              item?.ncbProtection?.isOpted
                ? item?.ncbProtection?.premium
                : item?.ncbProtection?.isOpted,
              item?.voluntaryDeductive?.isOpted,
              item?.voluntaryDeductive?.isOpted
                ? item?.voluntaryDeductive?.premium
                : item?.voluntaryDeductive?.isOpted,
              item?.passengerCover?.isOpted,
              item?.passengerCover?.isOpted
                ? item?.passengerCover?.premium
                : item?.passengerCover?.isOpted,
              item?.paCoverAmount?.isOpted,
              item?.paCoverAmount?.isOpted
                ? item?.paCoverAmount?.premium
                : item?.paCoverAmount?.isOpted,
              item?.lossOfPersonalBelongings?.isOpted,
              item?.lossOfPersonalBelongings?.isOpted
                ? item?.lossOfPersonalBelongings?.premium
                : item?.lossOfPersonalBelongings?.isOpted,
              item?.zeroDepriciationCover?.isOpted,
              item?.zeroDepriciationCover?.isOpted
                ? item?.zeroDepriciationCover?.premium
                : item?.zeroDepriciationCover?.isOpted,
              item?.keyReplacement?.isOpted,
              item?.keyReplacement?.isOpted
                ? item?.keyReplacement?.premium
                : item?.keyReplacement?.isOpted,
              item?.ncbDiscountAmount,
              item?.biFuelOd?.isOpted,
              item?.biFuelOd?.isOpted
                ? item?.biFuelOd?.premium
                : item?.biFuelOd?.isOpted,
              item?.biFuelTp?.isOpted,
              item?.biFuelTp?.isOpted
                ? item?.biFuelTp?.premium
                : item?.biFuelTp?.isOpted,
              item?.llToPaidDriver?.isOpted,
              item?.llToPaidDriver?.isOpted
                ? item?.llToPaidDriver?.premium
                : item?.llToPaidDriver?.isOpted,
              item?.caseId,
              item?.insurerId,
              item?.kycAdditionalData,
              item?.planId,
              item?.tppdDiscount ? item?.tppdDiscount : false,
              item?.loadingPremium ? item?.loadingPremium : false,
              '',
              '',
              item?.odTenure ? item?.odTenure : 0 ,
              item?.tpTenure ? item?.tpTenure :  0,
              item?.ncbDiscountPercentage ? item?.ncbDiscountPercentage : 0,
            )
          )
      )
    );
  };

  const setVehicleType = () => {
    vehicleModal === "4w"
      ? setPolicyInputs(pvtCarPolicyInfoArr)
      : vehicleModal === "2w"
      ? setPolicyInputs(bikePolicyInfoArr)
      : vehicleModal === "gcv"
      ? setPolicyInputs(gcvVehiclePolicyInfoArr)
      : vehicleModal === "pcv" &&
        setPolicyInputs(pcvVehiclePolicyInfoArr);
    setVehicleModel(vehicleModal);

    getVehicleMake({ vehicleType: vehicleModal }).then((response) => {
      if (response.data.length > 0) {
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.make,
            value: item.makeId,
          });
          i++;
        }
        setMakeData(arr);
        console.log('arrr',arr)
      }
    });
    dispatchQuickQuote("VehicleType", vehicleType);
    dispatchMotorQuote("vehicleType", vehicleType);
    vehicleModal === "2w"
      ? setQuickPick(twoWheelerMake)
      : vehicleModal === "4w"
      ? setQuickPick(fourWheelerModel)
      : vehicleModal === "pcv" && setQuickPick(fourWheelerModel);
  };

  const handleSelectMake = (make) => {
    const makeIdValue = makeData.filter((item)=> item.label == make)
    console.log("1");
    console.log("2");
    dispatchQuickQuote("MakeName", make);
    dispatchQuickQuote("ModelName", "");
    dispatchQuickQuote("VariantName", "");
    dispatchMotorQuote('makeName',make);
    dispatchMotorQuote('makeId',makeIdValue[0].value)
    getVehicleModel({ makeId: makeIdValue[0].value, vehicleType: vehicleModel }).then(
      (response) => {
        if (response.data.length > 0) {
          console.log("3");
          handleStepSelection(activeStep + 1);
          let data = response.data;
          let i = 0;
          let arr = [];
          while (i < data.length) {
            let item = data[i];
            arr.push({
              label: item.model,
              value: item.model,
              makeId: item.makeId,
              modelId: item.modelId,
            });
            i++;
          }
          setModelData(arr);
        }
      }
    );
    console.log("4");
  };

  const handleSelectModel = (model) => {
    const modelDataValue = modelData.filter((item) => item.label == model)
    console.log('modelDataValue',modelDataValue)
    dispatchQuickQuote("ModelName", model);
    dispatchQuickQuote("VariantName", "modelDataValue");
    dispatchMotorQuote('modelId',modelDataValue[0].modelId)
    getVehicleVariant({
      makeId: motorRequest.makeId,
      modelId: modelDataValue[0].modelId,
      vehicleType: vehicleModel,
    }).then((response) => {
      if (response.data.length > 0) {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.fuelType === v.fuelType) === i
        );
        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].fuelType);
          j++;
        }
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          console.log("item in varient code", item);
          arr.push({
            Digit: item.Digit,
            label: item.variant,
            value: item.variantId,
            Fuel_Type: item.fuelType,
            Cubic_Capacity: item.cc,
            Seating_Capacity: item.seats,
            HDFC: item.hdfc,
            Shriram: item.shriram,
            Kotak: item.kotak,
            Reliance: item.reliance,
            Future: item.future,
            Royal: item.royal,
            Bajaj: item.bajaj,
            vehicleId: item.id,
            Liberty: item.liberty,
            Magma: item.magma,
            Zuno: item.zuno,
          });
          i++;
        }
        setVariantData(arr);

        handleStepSelection(activeStep + 1);
      }
    });
  };

  const handleSelectPetrolType = (val) => {
    if (val == "CNG" || val == "LPG") {
      dispatchQuickQuote("IsInternalCNG", true);
    }
    dispatchQuickQuote("FuelType", val);
    handleStepSelection(activeStep + 1);
  };

  const submitUserDetails = (data) => {
    dispatchQuickQuote("idvRangeSet", false);
    // if (!termsAccepted) {
    //   setTncError(true);
    //   return;
    // }
    
    let newData = {
      FirstName: data.fullname.split(" ")[0],
      LastName: data.fullname.split(" ")[1],
      Email: data.Email,
      MobileNumber: data.MobileNumber,
    };

    let newDataStructure = {
      firstName: data.fullname.split(" ")[0],
      middleName: "",
      lastName: data.fullname.split(" ")[1],
      email: data.Email,
      mobileNumber: data.MobileNumber,
    };

    for (let key in newData) {
      dispatchQuickQuote(key, newData[key]);
    }

    for (let key in newDataStructure) {
      dispatchMotorQuote(key, newDataStructure[key]);
    }

    let postData = {
      name: newData.FirstName + "" + newData.LastName,
      email: newData.Email,
      phone: newData.MobileNumber,
    };

    handleStepSelection(activeStep + 1);
    // createCustomer(postData);
  };

  const handleSelectVariant = (variant) => {
    console.log("magma varient", variant.Magma);
    console.log("Zuno varient", variant.Zuno);
    dispatchMotorQuote("variantCode", variant.value);
    dispatchQuickQuote("VariantCode.Digit", variant.Digit);
    dispatchQuickQuote("VariantCode.HDFC", variant.HDFC);
    dispatchQuickQuote("VariantCode.Shriram", variant.Shriram);
    dispatchQuickQuote("VariantCode.Kotak", variant.Kotak);
    dispatchQuickQuote("VariantCode.Reliance", variant.Reliance);
    dispatchQuickQuote("VariantCode.Liberty", variant.Liberty);
    dispatchQuickQuote("VariantCode.Future", variant.Future);
    dispatchQuickQuote("VariantCode.Royal", variant.Royal);
    dispatchQuickQuote("VariantCode.Bajaj", variant.Bajaj);
    dispatchQuickQuote("VariantCode.Magma", variant.Magma);
    dispatchQuickQuote("VariantCode.Zuno", variant.Zuno);
    dispatchQuickQuote("VariantCode.vehicleId", variant.vehicleId);
    // let postdata = {
    //   VehicleType: apiRequestQQ.VehicleType,
    //   Make: apiRequestQQ.MakeName,
    //   Model: apiRequestQQ.ModelName,
    //   Variant: variant.label,
    //   CC: variant.Cubic_Capacity,
    //   fuelType: variant.Fuel_Type,
    // };

    dispatchQuickQuote("FuelType", variant.Fuel_Type);
    dispatchQuickQuote("VariantName", variant.label);
    dispatchQuickQuote("CarryingCapacity", variant.Seating_Capacity);
    dispatchQuickQuote("CubicCapacity", variant.Cubic_Capacity);
    // getBajajMMV(postdata).then((response) => {
    //   if (response.status === true) {
    //     if (response?.data) {
    //       dispatchQuickQuote("VariantCode.Bajaj", response.data.vehiclecode);
    //       dispatchQuickQuote("VehicleMakeCode", response.data.vehiclemakecode);
    //       dispatchQuickQuote("VehicleModelCode", response.data.vehiclemodelcode);
    //       dispatchQuickQuote("VehicleSubTypeCode", response.data.vehiclesubtypecode);
    //       dispatchQuickQuote("CarryingCapacity", response.data.carryingcapacity);
    //       dispatchQuickQuote("CubicCapacity", response.data.cubiccapacity);
    //       // dispatchQuickQuote("VariantName", response.data.vehiclesubtype);
    //     } else {
    //       dispatchQuickQuote("VariantName", variant.label);
    //     }
    //   } else {
    //     dispatchQuickQuote("VariantName", variant.label);
    //   }
    // });
    handleStepSelection(activeStep + 1);
  };

  const submitRegistrationNumber = () => {

    let registrationNumberWithoutHyphen = RegistrationNumber?.replace(/-/g, "");
    console.log(
      "registrationNumberWithoutHyphen",
      registrationNumberWithoutHyphen
    );

    getPolicyTenuare();
    let rtocode = RegistrationNumber.substring(0, 5)
      .replaceAll("-", "")
      .toUpperCase();
    let index = rtoData.findIndex((item) => item.value === rtocode);
    console.log("kcckbnkn", index);
    if (index === -1) {
      setError(true);
    }
    dispatchQuickQuote(
      "RegistrationCity",
      rtoData[index]?.registered_city_name.toUpperCase()
    );
    dispatchQuickQuote("State", rtoData[index].registered_state_name);
    dispatchQuickQuote("IsVehicleNew", false);
    dispatchQuickQuote("RTOCityName", rtocode);
    dispatchMotorQuote("rtoCode", rtocode)
    setIsNewVehicle(false);
    dispatchQuickQuote("RegistrationNumber", RegistrationNumber.toUpperCase());
    dispatchMotorQuote("registrationNumber", registrationNumberWithoutHyphen);

    apiRequestQQ.PolicyType === "Renewal"
      ? toggleStep(activeStep + 1)
      : toggleStep(activeStep);
  };

  const selectVehicleYear = (year) => {
    dispatchQuickQuote("RegistrationYear", year);
    dispatchMotorQuote("registrationDate", year);

    setRegYear(year);

    if (userDetails?.type && userDetails?.type === "pos") {
      handleStepSelection(activeStep + 2);
    } else {
      handleStepSelection(activeStep + 1);
    }
  };

  const navigate = useNavigate();

  const submitPolicyDetails = (data) => {
    console.log('predara',data)
    // dispatchQuickQuote(resetQuickQuoteResults());
    const currentNCB =
      data?.PreviousNoClaimBonus === "0"
        ? "20"
        : data?.PreviousNoClaimBonus === "20"
        ? "25"
        : data?.PreviousNoClaimBonus === "25"
        ? "35"
        : data?.PreviousNoClaimBonus === "35"
        ? "45"
        : data?.PreviousNoClaimBonus === "45"
        ? "50"
        : "";
    console.log("CUrrent NCB", currentNCB);
    dispatchQuickQuote("CurrentNoClaimBonus", currentNCB);
    console.log("APIREQUESTQQ", apiRequestQQ);

    let updatedStructureData = {
      newPolicyType:
        data?.NewPolicyType === "Comprehensive"
          ? POLICY_TYPE?.COMPREHENSIVE
          : data?.NewPolicyType === "ThirdParty"
          ? POLICY_TYPE?.THIRDPARTY
          : data?.NewPolicyType === "ODOnly"
          ? POLICY_TYPE?.ODONLY
          : data?.NewPolicyType === "Bundled"
          ? POLICY_TYPE?.BUNDLED
          : '',
      businessType:
        data?.PolicyStatus === "continue"
          ? POLICY_STATUS?.NOTEXPIRED
          : data?.PolicyStatus === "expired within 90 day"
          ? POLICY_STATUS?.EXPIREDWITHIN90
          : POLICY_STATUS?.EXPIREDBEFORE90,
      prevPolicyEndDate: data?.PrePolicyEndDate ? data?.PrePolicyEndDate : '' ,
      prevPolicyType:
        data?.PreviousPolicyType === "Comprehensive"
          ? POLICY_TYPE?.COMPREHENSIVE
          : data?.PreviousPolicyType === "ThirdParty"
          ? POLICY_TYPE?.THIRDPARTY
          : data?.PreviousPolicyType === "ODOnly"
          ? POLICY_TYPE?.ODONLY
          : data?.PreviousPolicyType === "Bundled"
          ? POLICY_TYPE?.BUNDLED
          : '',
      insurerCode: insurerData.find(
        (insurer) => insurer?.value === data?.PreInsurerCode
      )?.prevInsurerId || "",
      registrationDate: data?.RegistrationDate,
      manufaturingDate : data?.ManufaturingDate + '-01'

    };

    // debugger

    if (motorRequest?.isVehicleNew) {
      updatedStructureData = { ...updatedStructureData, businessType: "new" ,prevNoClaimBonus : 0};
      dispatchMotorQuote("businessType", "new");
      dispatchMotorQuote("prevNoClaimBonus", 0);
    }

    console.log('insurerCode',updatedStructureData)
  
    for (let key in updatedStructureData) {
      dispatchMotorQuote(`${key}`, updatedStructureData[key]);
    }
  
    // dispatchMotorQuote('prevNoClaimBonus', currentNCB);
    if(!motorRequest?.isVehicleNew){
    dispatchMotorQuote('prevNoClaimBonus', data?.PreviousNoClaimBonus)};
    // dispatchMotorQuote('manufaturingDate', data.ManufaturingDate + '-01');



    let newData = {
      ...apiRequestQQ,
      CurrentNoClaimBonus: currentNCB,
      userDetails: userDetails,
    };

    console.log("NEW DATAAAAAA", newData);
    if (
      checkIfDateIsIn3Month(data.PrePolicyEndDate) &&
      apiRequestQQ.IsVehicleNew === false &&
      apiRequestQQ.PolicyStatus == "continue"
    ) {
      const startDate = moment(data.PrePolicyEndDate)
        .add(1, "day")
        .format("YYYY-MM-DD");
      const endDate = moment(startDate, "YYYY-MM-DD")
        .add(1, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
      newData.PolicyStartDate = startDate;
      newData.PolicyEndDate = endDate;
    } else if (apiRequestQQ.IsVehicleNew === false) {
      const startDate = moment().add(1, "day").format("YYYY-MM-DD");
      const endDate = moment(startDate, "YYYY-MM-DD")
        .add(1, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
      newData.PolicyStartDate = startDate;
      newData.PolicyEndDate = endDate;
    }
    for (let key in data) {
      if (Object.keys(apiRequestQQ).includes(key)) {
        if (key === "PolicyStatus" && data.PolicyStatus == "false") {
          dispatchQuickQuote("IsPreviousInsurerKnown", false);
          dispatchQuickQuote("PolicyStatus", "continue");
        }
        newData[key] = data[key];
        console.log("dispatchQuickQuote", key, data[key]);
        dispatchQuickQuote(key, data[key]);
      }
    }
    if (data?.PolicyStatus === "false") {
      newData = { ...newData, IsPreviousInsurerKnown: false };
    }
    // console.log("NEW DATAA FOR KOTAK", newData);

    console.log(motorRequest);
    console.log(data);
    console.log(motorRequest);


    const updatedData = {

      ...motorRequest,
      customerType: data?.CustomerType === "INDIVIDUAL" 
      ? CUSTOMER_TYPE.INDIVIDUAL 
      : data?.CustomerType === "COMPANY" 
      ? CUSTOMER_TYPE.ORGANISATION 
      : '',
      manufaturingDate: data.ManufaturingDate + '-01',
      policyStatus: data.PolicyStatus,
      prevPolicyEndDate: data?.PrePolicyEndDate ? data?.PrePolicyEndDate : '',
      isPreviousInsuranceClaimed : data.PreviousInsuranceClaimed ? JSON.parse(data.PreviousInsuranceClaimed) : '' ,
      prevNoClaimBonus : data.PreviousNoClaimBonus ? data.PreviousNoClaimBonus : 0 , 

      registrationDate : data.RegistrationDate,
      newPolicyType:
      data?.NewPolicyType === "Comprehensive"
        ? POLICY_TYPE?.COMPREHENSIVE
        : data?.NewPolicyType === "ThirdParty"
        ? POLICY_TYPE?.THIRDPARTY
        : data?.NewPolicyType === "ODOnly"
        ? POLICY_TYPE?.ODONLY
        : data?.NewPolicyType === "Bundled"
        ? POLICY_TYPE?.BUNDLED
        : '',
        businessType:
          motorRequest?.isVehicleNew ? POLICY_STATUS.NEW : data?.PolicyStatus === "continue"
            ? POLICY_STATUS?.NOTEXPIRED
            : data?.PolicyStatus === "expired within 90 day"
            ? POLICY_STATUS?.EXPIREDWITHIN90
            : data?.PolicyStatus === "expired above 90 day" ? POLICY_STATUS?.EXPIREDBEFORE90 : null,
        prevPolicyType:
          data?.PreviousPolicyType === "Comprehensive"
            ? POLICY_TYPE?.COMPREHENSIVE
            : data?.PreviousPolicyType === "ThirdParty"
            ? POLICY_TYPE?.THIRDPARTY
            : data?.PreviousPolicyType === "ODOnly"
            ? POLICY_TYPE?.ODONLY
            : data?.PreviousPolicyType === "Bundled"
            ? POLICY_TYPE?.BUNDLED
            : '',
    insurerCode: insurerData.find(
      (insurer) => insurer?.value === data?.PreInsurerCode
    )?.prevInsurerId || '',
    // registrationDate: data?.RegistrationDate,

    }

    dispatchMotorQuote('customerType',updatedData.customerType)

    console.log(updatedData)

    motorQuotation(updatedData);

    // debugger

    console.log('mototrrrrr',motorRequest.customerLeadId)

    navigate(`/quotelist/${motorRequest?.customerLeadId}`);

    setTimeout(() => {
      savePolicyToDatabase(newData);
      // digitAPICall(newData);

      if (
        moment().format("YYYY") - apiRequestQQ.RegistrationYear <= 15 &&
        newData.PolicyStatus == "continue"
      ) {
        // bajajApiCall(newData);
      }
      // shriramGenerateProposal(newData, false);
      if (newData.PreInsurerCode != "152") {
        // KotakAPICall(newData);
      }
      // fasdfasdfasdf
      if (
        moment().format("YYYY") - apiRequestQQ.RegistrationYear <= 15 &&
        (apiRequestQQ.NewPolicyType != "ThirdParty" ||
          newData.PolicyStatus == "continue")
      ) {
        // RelianceAPICall(newData);
      }
      // FutureGeneralQuickQuote(newData);

      // getZunoQuickQuote(newData);

      const newObj = {
        ...newData,
        AddOns: {
          ...newData.AddOns,
          PersonalAccident: true,
        },
      };

      console.log("newObj", newObj);

      // magmaAPICall(newObj);

      // RoyalSundramAPICall(newData);
      if (
        (moment().format("YYYY") - apiRequestQQ.RegistrationYear <= 15 &&
          newData.PolicyStatus === "continue") ||
        (apiRequestQQ?.NewPolicyType === "ThirdParty" &&
          newData?.PolicyStatus === "expired within 90 day") ||
        (apiRequestQQ?.NewPolicyType === "ThirdParty" &&
          newData?.PolicyStatus === "expired above 90 day")
      ) {
        const newObj = {
          ...newData,
          AddOns: {
            ...newData.AddOns,
            PersonalAccident: true,
          },
        };
        // HDFCCalculatePremium(newObj);
      }
      // getLibertyAPICall(newData);

      `/quotelist/${motorRequest?.customerLeadId}`

      // navigate("/quotelist");
    }, 2000);
  };

  const formatReg = (e) => {
    var t = e;
    t = t.replace(/-/g, "");
    var o = new Array(4);
    (o[0] = t.slice(0, 2)), (o[1] = ""), (o[2] = ""), (o[3] = "");
    try {
      isNaN(t.slice(2, 4))
        ? isNaN(t.slice(3, 4))
          ? (isNaN(t.slice(2, 3)) || (o[1] = t.slice(2, 3)),
            isNaN(t.slice(3, 4)) && (o[2] = t.slice(3, 4)))
          : (o[1] = t.slice(2, 3) + t.slice(3, 4))
        : (o[1] = t.slice(2, 4)),
        isNaN(t.slice(4, 8))
          ? ((o[2] = o[2] + t.slice(4, 5)),
            1 == isNaN(t.slice(5, 6)) && 1 == isNaN(t.slice(6, 7))
              ? (o[2] = o[2] + t.slice(5, 7))
              : (isNaN(t.slice(5, 6))
                  ? (o[2] = o[2] + t.slice(5, 6))
                  : (o[3] = o[3] + t.slice(5, 6)),
                isNaN(t.slice(6, 7)) || (o[3] = o[3] + t.slice(6, 7))),
            isNaN(t.slice(7, 11)) || (o[3] = o[3] + t.slice(7, 11)))
          : (o[3] = o[3] + t.slice(4, 8));
    } catch (e) {}
    return o
      .join("-")
      .replace(/ /g, "")
      .replace(/--/g, "-")
      .replace(/-\s*$/, "")
      .replace(/[^- 0-9 a-z A-z]/g, "");
  };

  const setRegistrationValue = (e) => {
    if (navigator.userAgent.match(/UCBrowser/)) return !1;
    var t = document.querySelector("#vinpuut").value,
      o = e.keyCode;
    if (
      -1 != navigator.userAgent.indexOf("MSIE") ||
      1 == !!document.documentMode
    ) {
      if (32 == o || 8 == o) return !1;
      t.length >= 3 && (t = formatReg(t)),
        t.length >= 5 && (t = formatReg(t)),
        t.length >= 7 && (t = formatReg(t)),
        (document.querySelector("#vinpuut").value = t.toUpperCase());
    } else
      setTimeout(function () {
        if (32 == o || 8 == o) return !1;
        (t = document.querySelector("#vinpuut").value).length >= 3 &&
          (t = formatReg(t)),
          t.length >= 5 && (t = formatReg(t)),
          t.length >= 7 && (t = formatReg(t)),
          (document.querySelector("#vinpuut").value = t.toUpperCase());
      }, 100);
    setRegistrationNumner(t);
  };

  const handleSelectRtoCity = (val) => {
    dispatchQuickQuote("State", val.registered_state_name);
    getPolicyTenuare();
    dispatchQuickQuote("RtoCode", val.value);
    dispatchMotorQuote("rtoCode", val.value);
    dispatchQuickQuote(
      "RegistrationCity",
      val.registered_city_name.toUpperCase()
    );
    processFlow().then((response) => {
      console.log("RESPONSE", response);
      dispatchMotorQuote(
        "customerLeadId",
        response?.data?.customerLeadId
      );
    });
    handleStepSelection(activeStep + 1);
  };

  const getPolicyTenuare = () => {
    if (isNewVehicle === true && vehicleType == "2w") {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(5, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
    } else if (isNewVehicle === true && vehicleType == "4w") {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(3, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
    } else {
      const startDate = moment().format("YYYY-MM-DD");
      const endDate = moment()
        .add(1, "year")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      dispatchQuickQuote("PolicyStartDate", startDate);
      dispatchQuickQuote("PolicyEndDate", endDate);
    }
  };

  const handleButtonClick = () => {
    setShowSecondComponent(true);
  };

  const handleStepSelection = (step) => {
    setActiveStep(step);
  };

  const handleNextClick = () => {
    const currentTabId = `step${activeStep}`;
    const currentTabPane = document.getElementById(currentTabId);
    if (currentTabPane) {
      currentTabPane.classList.remove("show", "active");
    }

    setActiveStep(activeStep - 1);

    const prevTabId = `step${activeStep - 1}`;
    const prevTabPane = document.getElementById(prevTabId);
    if (prevTabPane) {
      prevTabPane.classList.add("show", "active");
    }
  };

  return (
    <>
      {showSecondComponent ? (
        <InsuranceSteps
          vehicleType={vehicleType}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          handleStepSelection={handleStepSelection}
          handleNextClick={handleNextClick}
          rtoData={rtoData}
          handleSelectRtoCity={handleSelectRtoCity}
          handleSelectMake={handleSelectMake}
          makeData={makeData}
          quickPick={quickPick}
          handleSelectModel={handleSelectModel}
          modelData={modelData}
          handleSelectPetrolType={handleSelectPetrolType}
          fuelTypes={fuelTypes}
          handleSelectVariant={handleSelectVariant}
          variantData={variantData}
          apiRequestQQ={apiRequestQQ}
          isNewVehicle={isNewVehicle}
          selectVehicleYear={selectVehicleYear}
          submitUserDetails={submitUserDetails}
          regYear={regYear}
          insurerData={insurerData}
          submitPolicyDetails={submitPolicyDetails}
          termsAccepted={termsAccepted}
          setTermsAccepted={setTermsAccepted}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          onBack={() => setShowSecondComponent(false)}
          tncError={tncError}
          setTncError={setTncError}
          formsError={formsError}
          selectValueError={selectValueError}
        />
      ) : (
        <InsuranceHome
          handleClick={handleButtonClick}
          vehicleType={vehicleType}
          setRegistrationValue={setRegistrationValue}
          submitRegistrationNumber={submitRegistrationNumber}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setIsNewVehicle={setIsNewVehicle}
          error={error}
          setError={setError}
          flowApi={flowApi}
        />
      )}
    </>
  );
};

export default HomeSection;

const checkIfDateIsIn3Month = (dateToCheck) => {
  return (
    moment().diff(dateToCheck, "days") > -90 &&
    moment().diff(dateToCheck, "days") <= 0
  );
};

export const InsuranceHome = ({
  handleClick,
  vehicleType,
  setRegistrationValue,
  submitRegistrationNumber,
  activeStep,
  setActiveStep,
  setIsNewVehicle,
  error,
  setError,
  flowApi
}) => {
  return (
    <div>
      <Header />
      <div className="healthinsuranceSec">
        <div className="container">
          <div className="healthinsurance carinsurancesec">
            <div className="healthinsimg">
              <img
                src={
                  vehicleType == "4w"
                    ? "/assetss/images/carImgNew1.jpeg"
                    : vehicleType == "2w"
                    ? "/assetss/images/twowheelerimg.png"
                    : vehicleType == "pcv"
                    ? "/assetss/images/taxiimgsm.png"
                    : vehicleType == "gcv" && "/assetss/images/GCVInsurance.png"
                }
                className="w-100 carmodalimg ps-lg-5 "
                alt=""
              />
            </div>

            <div className="twowheelerinsu">
              <div className="container-fluid p-lg-5">
                <aside className="title text-start mt-lg-0 mt-3">
                  <span className="herotitle">
                    {vehicleType == "4w" ? (
                      <h2>
                        Compare & Save <span>upto 85%* on</span> Car Insurance
                      </h2>
                    ) : vehicleType == "2w" ? (
                      <h2>
                        Buy your two-wheeler insurance in{" "}
                        <span>60 seconds!</span>
                      </h2>
                    ) : vehicleType == "pcv" ? (
                      <h2>
                        Compare & Save <span>upto 85%* on</span> Taxi Insurance
                      </h2>
                    ) : (
                      vehicleType == "gcv" && "GCV"
                    )}{" "}
                  </span>
                </aside>

                <ul className="nav nav-tabs tabsmenu" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="bikestep1-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bikestep1"
                      type="button"
                      role="tab"
                      aria-controls="bikestep1"
                      aria-selected="true"
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ marginRight: "0.7rem" }}
                      >
                        <circle
                          cx="17.5"
                          cy="17.5"
                          r="17"
                          fill="white"
                          stroke="#00B0CB"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.0909 20.4925L10.2727 16.7313L9 17.9851L14.0909 23L25 12.2537L23.7273 11L14.0909 20.4925Z"
                          fill="#00B0CB"
                        />
                      </svg>
                      Rollover
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="bikestep2-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bikestep2"
                      type="button"
                      role="tab"
                      aria-controls="bikestep2"
                      aria-selected="false"
                      onClick={() => {
                        dispatchQuickQuote("IsVehicleNew", true);
                        dispatchMotorQuote("isVehicleNew", true);
                        setIsNewVehicle(true);
                        setActiveStep(1);
                        handleClick();
                      }}
                    >
                      <svg
                        width="30"
                        height="30"
                        viewBox="0 0 35 35"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="17.5"
                          cy="17.5"
                          r="17"
                          fill="white"
                          stroke="#00B0CB"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14.0909 20.4925L10.2727 16.7313L9 17.9851L14.0909 23L25 12.2537L23.7273 11L14.0909 20.4925Z"
                          fill="#00B0CB"
                        />
                      </svg>{" "}
                      New
                    </button>
                  </li>
                </ul>

                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="bikestep1"
                    role="tabpanel"
                    aria-labelledby="bikestep1-tab"
                  >
                    <div className="twoinsuranceform">
                      <input
                        type="text"
                        className="form-control fs-3"
                        id="vinpuut"
                        // value={RegistrationNumber}
                        style={{ textTransform: "uppercase" }}
                        onChange={(e) => {
                          setRegistrationValue(e);
                          setError(false);
                        }}
                        placeholder="Enter Your Vehicle Registration Number"
                      />
                      <label htmlFor="vinpuut" className="d-none">
                        Enter Your Registration Number
                      </label>

                      {/* <p
                        className="f-error fs-3 mx-3"
                        style={{ position: "relative", bottom: "1rem" }}
                      >
                        Input invalid
                      </p> */}
                    </div>
                    {error && (
                      <p
                        className="f-error fs-3 mx-2"
                        style={{ position: "relative", bottom: "1rem" }}
                      >
                        Enter a valid registration number
                      </p>
                    )}

                    <div className="text-start mt-5">
                      <button
                        type="button"
                        onClick={() => {
                          submitRegistrationNumber();
                          handleClick();
                          flowApi()
                        }}
                        className="btn btn-primary"
                      >
                        Get Your Quote
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WhyExpertCoverBuy />
        {vehicleType == "4w" ? (
          <CarInsuranceDetails />
        ) : vehicleType == "2w" ? (
          <BikeInsuranceDetails />
        ) : vehicleType == "pcv" ? (
          <TaxiInsuranceDetails />
        ) : (
          vehicleType == "gcv" && "GCV"
        )}{" "}
        <Footer />
      </div>
    </div>
  );
};

export const InsuranceSteps = ({
  vehicleType,
  activeStep,
  setActiveStep,
  handleStepSelection,
  handleNextClick,
  rtoData,
  handleSelectRtoCity,
  handleSelectMake,
  makeData,
  quickPick,
  handleSelectModel,
  modelData,
  fuelTypes,
  handleSelectPetrolType,
  variantData,
  apiRequestQQ,
  handleSelectVariant,
  isNewVehicle,
  selectVehicleYear,
  submitUserDetails,
  regYear,
  insurerData,
  submitPolicyDetails,
  termsAccepted,
  setTermsAccepted,
  currentStep,
  setCurrentStep,
  onBack,
  tncError,
  setTncError,
}) => {
  const {
    handleSubmit: handleSubmit1,
    register: register1,
    formState: { errors: errors1 },
  } = useForm({ mode: "onBlur" });
  const {
    handleSubmit: handleSubmit2,
    register: register2,
    watch: watch2,
    formState: { errors: errors2 },
  } = useForm({ mode: "onBlur" });

  const previousPolicyKnow = useRef(null);
  previousPolicyKnow.current = watch2("PolicyStatus");

  const ManufaturingDate = useRef(null);
  ManufaturingDate.current = watch2("ManufaturingDate");

  const RegistrationDate = useRef(null);
  RegistrationDate.current = watch2("RegistrationDate");
  let minDate =
    previousPolicyKnow.current === "continue"
      ? moment().format(`YYYY-MM-DD`)
      : previousPolicyKnow.current === "expired within 90 day"
      ? moment().subtract(90, "day").format(`YYYY-MM-DD`)
      : previousPolicyKnow.current === "expired above 90 day"
      ? moment().subtract(180, "day").format(`YYYY-MM-DD`)
      : "";
  let maxDate =
    previousPolicyKnow.current === "continue"
      ? moment().add(90, "day").format(`YYYY-MM-DD`)
      : previousPolicyKnow.current === "expired above 90 day"
      ? moment().subtract(90, "day").format(`YYYY-MM-DD`)
      : moment().format(`YYYY-MM-DD`);

  //   const geenrateMfgYears = () => {
  //     var max = regYear;
  //     var min = regYear - 18;
  //     var years = [];
  //     for (let i = max; i >= min; i--) {
  //       years.push(i);
  //     }
  //     return years;
  //   };

  let calculateYearDifference = (registrationDate, currentDate) => {
    const date1 = registrationDate;
    const date2 = currentDate.getFullYear();

    const yearsDifference = date2 - date1;

    console.log("Years Difference", yearsDifference);
    return yearsDifference;
  };

  let yearDiff = calculateYearDifference(
    apiRequestQQ.RegistrationYear,
    new Date()
  );

  console.log("yearDiff : ", yearDiff);

  useEffect(() => {
    console.log("yearDiff : ", yearDiff);
  }, [yearDiff]);

  const handleKeyPress = (event) => {

    const invalidChar = /[^A-Za-z ]/;
    if (invalidChar.test(event.key)) {
      event.preventDefault();
    }
  };

  const handleKeyPress2 = (event) => {

    const invalidChar = /[^0-9]/;
    if (invalidChar.test(event.key)) {
      event.preventDefault();
    }
  };

  // const handleNextClick = () => {
  //   const currentTabId = `step${activeStep}`;
  //   const currentTabPane = document.getElementById(currentTabId);
  //   if (currentTabPane) {
  //     currentTabPane.classList.remove("show", "active");
  //   }

  //   setActiveStep(activeStep + 1);

  //   const nextTabId = `step${activeStep + 1}`;
  //   const nextTabPane = document.getElementById(nextTabId);
  //   if (nextTabPane) {
  //     nextTabPane.classList.add("show", "active");
  //   }
  // };

  // const handleStepSelection = (step) => {
  //   setActiveStep(step);
  // };

  return (
    <div>
      <Header />
      <div className="healthinsuranceSec">
        <div className="container">
          <div
            className="healthinsurance twowheelerinsusec"
            style={{ width: "100%" }}
          >
            <div className="healthinsimg">
              <img
                src={
                  vehicleType == "4w"
                    ? "/assetss/images/carImgNew1.jpeg"
                    : vehicleType == "2w"
                    ? "/assetss/images/twowheelerimg.png"
                    : vehicleType == "pcv"
                    ? "/assetss/images/PCVInsurance.png"
                    : vehicleType == "gcv" && "/assetss/images/GCVInsurance.png"
                }
                className="w-100 carmodalimg"
                style={{ marginRight: "10px" }}
                alt=""
              />
            </div>
            <div className="twowheelerinsu">
              <ul className="nav nav-tabs formsteps" id="myTab1" role="tablist">
                <li
                  className="nav-item"
                  role="presentation"
                  style={{ fontSize: "10px" }}
                >
                  <button
                    className={`nav-link ${activeStep === 1 ? "active" : ""}`}
                    // onClick={() => handleStepSelection(1)}
                    id="step1-tab"
                    // data-bs-toggle="tab"
                    // data-bs-target="#step1"
                    // type="button"
                    role="tab"
                    aria-controls="step1"
                    aria-selected={activeStep === 1}
                    style={{ fontSize: "17px" }}
                  >
                    1
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeStep === 2 ? "active" : ""}`}
                    // onClick={() => handleStepSelection(2)}
                    id="step2-tab"
                    // data-bs-toggle="tab"
                    // data-bs-target="#step2"
                    // type="button"
                    role="tab"
                    aria-controls="step2"
                    aria-selected={activeStep === 2}
                  >
                    2
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeStep === 3 ? "active" : ""}`}
                    // onClick={() => handleStepSelection(3)}
                    id="step3-tab"
                    // data-bs-toggle="tab"
                    // data-bs-target="#step3"
                    // type="button"
                    role="tab"
                    aria-controls="step3"
                    aria-selected={activeStep === 3}
                  >
                    3
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeStep === 4 ? "active" : ""}`}
                    // onClick={() => handleStepSelection(4)}
                    id="step4-tab"
                    // data-bs-toggle="tab"
                    // data-bs-target="#step4"
                    // type="button"
                    role="tab"
                    aria-controls="step4"
                    aria-selected={activeStep === 4}
                  >
                    4
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeStep === 5 ? "active" : ""}`}
                    // onClick={() => handleStepSelection(5)}
                    id="step5-tab"
                    // data-bs-toggle="tab"
                    // data-bs-target="#step5"
                    // type="button"
                    role="tab"
                    aria-controls="step5"
                    aria-selected={activeStep === 5}
                  >
                    5
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeStep === 6 ? "active" : ""}`}
                    // onClick={() => handleStepSelection(6)}
                    id="step6-tab"
                    // data-bs-toggle="tab"
                    // data-bs-target="#step6"
                    // type="button"
                    role="tab"
                    aria-controls="step6"
                    aria-selected={activeStep === 6}
                  >
                    6
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeStep === 7 ? "active" : ""}`}
                    // onClick={() => handleStepSelection(7)}
                    id="step7-tab"
                    // data-bs-toggle="tab"
                    // data-bs-target="#step7"
                    // type="button"
                    role="tab"
                    aria-controls="step7"
                    aria-selected={activeStep === 7}
                  >
                    7
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link ${activeStep === 8 ? "active" : ""}`}
                    // onClick={() => handleStepSelection(8)}
                    id="step8-tab"
                    // data-bs-toggle="tab"
                    // data-bs-target="#step8"
                    // type="button"
                    role="tab"
                    aria-controls="step8"
                    aria-selected={activeStep === 8}
                  >
                    8
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent1">
                {activeStep === 1 && (
                  <div
                    className={`tab-pane fade ${
                      activeStep === 1 ? "show active" : ""
                    }`}
                    id="step1"
                    role="tabpanel"
                    aria-labelledby="step1-tab"
                  >
                    <div className="cityforbike">
                      <div className="selectcitybike">
                        <h2>Select City / RTO</h2>
                        <div>
                          <ReactSelect
                            options={rtoData}
                            name="rto"
                            placeholder="Select RTO Location"
                            onChange={(val) => handleSelectRtoCity(val)}
                          />
                        </div>
                      </div>

                      <div className="cityTab">
                        <h2>Select city</h2>
                        <div className="cityselectionform">
                          <div className="tab-content" id="myTabContent2">
                            <div
                              className="tab-pane fade show active"
                              id="citystep1"
                              role="tabpanel"
                              aria-labelledby="citystep1-tab"
                            >
                              <div className="allcitybike">
                                <ul>
                                  {rtoData &&
                                    rtoData.length > 0 &&
                                    rtoData
                                      .filter(
                                        (item) =>
                                          item.registered_state_name ===
                                          "RAJASTHAN"
                                      )
                                      .slice(0, 20)
                                      .map((item, index) => (
                                        <>
                                          <li
                                            className="active"
                                            id={"rto" + item.id}
                                            name="regcitycode"
                                            onClick={() =>
                                              handleSelectRtoCity(item)
                                            }
                                          >
                                            <button htmlFor={"rto" + item.id}>
                                              {item.value}
                                            </button>
                                          </li>
                                        </>
                                      ))}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <button
                            // disabled={apiRequestQQ.RtoCode}
                            className="btn btn-primary"
                            onClick={onBack}
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeStep === 2 && (
                  <div
                    className={`tab-pane fade ${
                      activeStep === 2 ? "show active" : ""
                    }`}
                    id="step2"
                    role="tabpanel"
                    aria-labelledby="step2-tab"
                  >
                    <div className="insuranceform">
                      <div className="selectcitybike">
                        <h2>Select the Wheeler Manufacture</h2>
                        <ReactSelect
                          options={makeData}
                          name="make"
                          placeholder="Select Manufacturer"
                          onChange={(val) => handleSelectMake(val.value)}
                        />
                      </div>
                      <div className="popularmanufac">
                        <h2>Popular Manufactures</h2>
                        <ul>
                          {vehicleType == "2w" ||
                          vehicleType == "4w" ||
                          vehicleType == "pcv"
                            ? quickPick.map((item, i) => (
                                <li
                                  value={item.value}
                                  className="form-control"
                                  id={"idx" + i}
                                  name={"make"}
                                  onClick={() => handleSelectMake(item.value)}
                                >
                                  <div>
                                    <img
                                      src={item.image}
                                      className="img-fluid w-100 h-100 "
                                      alt=""
                                    />
                                    {/* <i className="fas fa-check-circle text-success" /> */}
                                  </div>
                                </li>
                              ))
                            : makeData &&
                              makeData.length > 0 &&
                              makeData.slice(0, 15).map((item, index) => (
                                <li
                                  className="form-control"
                                  id={"make11" + index}
                                  name="carComany"
                                >
                                  <button
                                    onClick={() => handleSelectMake(item.value)}
                                    htmlFor={"make11" + index}
                                  >
                                    {item.value}
                                  </button>
                                </li>
                              ))}
                        </ul>
                        {/* <div className="moremenufa">
                          <a href="#">More +</a>
                        </div> */}
                        {isNewVehicle ? (
                          <button
                            className="btn btn-primary"
                            onClick={handleNextClick}
                          >
                            Previous
                          </button>
                        ) : (
                          <button
                            disabled={true}
                            className="btn btn-primary"
                            onClick={handleNextClick}
                          >
                            Previous
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {activeStep === 3 && (
                  <div
                    className={`tab-pane fade ${
                      activeStep === 3 ? "show active" : ""
                    }`}
                    id="step3"
                    role="tabpanel"
                    aria-labelledby="step3-tab"
                  >
                    <div className="insuranceform">
                      <div className="selectcitybike">
                        {vehicleType == "4w" ? (
                          <h2>Select Four Wheeler Model</h2>
                        ) : vehicleType == "2w" ? (
                          <h2>Select Two Wheeler Model</h2>
                        ) : vehicleType == "pcv" ? (
                          <h2>Select Two Wheeler Model</h2>
                        ) : (
                          vehicleType == "gcv" && "GCV"
                        )}

                        <ReactSelect
                          options={modelData}
                          name="model"
                          placeholder="Select vehicle model"
                          onChange={(val) => handleSelectModel(val.value)}
                        />
                      </div>

                      <div className="popularmodal">
                        <h3>Popular Models</h3>
                        <ul className="existingIllness">
                          {modelData &&
                            modelData.length > 0 &&
                            modelData.slice(0, 20).map((item, index) => (
                              <>
                                <li
                                  onClick={() => handleSelectModel(item.value)}
                                  id={"rto11" + index}
                                  name="carComanY"
                                >
                                  <label
                                    className="illnessbox"
                                    htmlFor={"rto11" + index}
                                  >
                                    {item.value}
                                    <input type="radio" name="radio" />
                                    <span className="illcheckmark"></span>
                                  </label>
                                </li>
                              </>
                            ))}
                        </ul>
                      </div>

                      <button
                        // disabled={apiRequestQQ.ModelName == ""}
                        className="btn btn-primary"
                        onClick={handleNextClick}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                )}

                {activeStep === 4 && (
                  <div
                    className={`tab-pane fade ${
                      activeStep === 4 ? "show active" : ""
                    }`}
                    id="step4"
                    role="tabpanel"
                    aria-labelledby="step1-tab"
                  >
                    <div className="cityforbike">
                      <div className="selectcitybike">
                        <h2>Select Fuel Type</h2>
                      </div>

                      <div className="cityTab">
                        <div className="cityselectionform">
                          <div className="tab-content" id="myTabContent2">
                            <div
                              className="tab-pane fade show active"
                              id="citystep4"
                              role="tabpanel"
                              aria-labelledby="citystep4-tab"
                            >
                              <div className="allcitybike">
                                <ul>
                                  {fuelTypes.includes("petrol") && (
                                    <>
                                      <li
                                        onClick={() =>
                                          handleSelectPetrolType("petrol")
                                        }
                                        className="active"
                                        id={"rto11petrol"}
                                        name="carComany"
                                      >
                                        <button htmlFor={"rto11petrol"}>
                                          Petrol
                                        </button>
                                      </li>
                                    </>
                                  )}
                                  {fuelTypes.includes("diesel") && (
                                    <>
                                      <li
                                        onClick={() =>
                                          handleSelectPetrolType("diesel")
                                        }
                                        className="active"
                                        id={"rto11diesel"}
                                        name="carComany"
                                      >
                                        <button htmlFor={"rto11diesel"}>
                                          Diesel
                                        </button>
                                      </li>
                                    </>
                                  )}
                                  {fuelTypes.includes("battery") && (
                                    <>
                                      <li
                                        onClick={() =>
                                          handleSelectPetrolType("battery")
                                        }
                                        className="active"
                                        id={"rto11ele"}
                                        name="carComany"
                                      >
                                        <button htmlFor={"rto11ele"}>
                                          Electric
                                        </button>
                                      </li>
                                    </>
                                  )}
                                  {fuelTypes.includes("cng") && (
                                    <>
                                      <li
                                        onClick={() =>
                                          handleSelectPetrolType("cng")
                                        }
                                        className="active"
                                        id={"rto11cng"}
                                        name="carComany"
                                      >
                                        <button htmlFor={"rto11cng"}>
                                          CNG
                                        </button>
                                      </li>
                                    </>
                                  )}
                                  {fuelTypes.includes("lpg") && (
                                    <>
                                      <li
                                        onClick={() =>
                                          handleSelectPetrolType("lpg")
                                        }
                                        className="active"
                                        id={"rto11cng"}
                                        name="carComany"
                                      >
                                        <button htmlFor={"rto11cng"}>
                                          LPG
                                        </button>
                                      </li>
                                    </>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <button
                            disabled={apiRequestQQ.fuelTypes == ""}
                            className="btn btn-primary"
                            onClick={handleNextClick}
                          >
                            Previous
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeStep === 5 && (
                  <div
                    className={`tab-pane fade ${
                      activeStep === 5 ? "show active" : ""
                    }`}
                    id="step5"
                    role="tabpanel"
                    aria-labelledby="step5-tab"
                  >
                    <div className="insuranceform">
                      <div className="selectcitybike">
                        {vehicleType == "4w" ? (
                          <h2>Select Four Wheeler Variant</h2>
                        ) : vehicleType == "2w" ? (
                          <h2>Select Two Wheeler Variant</h2>
                        ) : vehicleType == "pcv" ? (
                          <h2>Select Two Wheeler Variant</h2>
                        ) : (
                          vehicleType == "gcv" && "GCV"
                        )}

                        <ReactSelect
                          options={variantData.filter(
                            (item) => item.Fuel_Type == apiRequestQQ.FuelType
                          )}
                          name="model"
                          placeholder="Select vehicle variant"
                          onChange={(val) => handleSelectVariant(val)}
                        />
                      </div>

                      <div className="popularmodal wheelervarient">
                        <ul className="existingIllness">
                          {variantData &&
                            variantData.length > 0 &&
                            variantData
                              .filter(
                                (item) =>
                                  item.Fuel_Type == apiRequestQQ.FuelType
                              )
                              .slice(0, 20)
                              .map((item, index) => (
                                <>
                                  <li id={"rot12" + index} name="carComanY11">
                                    <label
                                      className="illnessbox"
                                      htmlFor={"rot12" + index}
                                      onClick={() => handleSelectVariant(item)}
                                      value={item.value}
                                    >
                                      {item.label}
                                      <input type="radio" name="radio" />
                                      <span className="illcheckmark"></span>
                                    </label>
                                  </li>
                                </>
                              ))}
                        </ul>
                      </div>
                      <button
                        // disabled={apiRequestQQ.VariantName == ""}
                        className="btn btn-primary"
                        onClick={handleNextClick}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                )}
                {activeStep === 6 && (
                  <div
                    className={`tab-pane fade ${
                      activeStep === 6 ? "show active" : ""
                    }`}
                    id="step6"
                    role="tabpanel"
                    aria-labelledby="step6-tab"
                  >
                    <div className="insuranceform">
                      <div className="selectcitybike">
                        <h2>Select Registration Year</h2>
                      </div>

                      <div className="popularmodal regyear">
                        <ul className="existingIllness">
                          {generateArrayOfYears()
                            .filter((item, i) => {
                              if (isNewVehicle === true) {
                                return i < 1;
                              } else {
                                return i > 0;
                              }
                            })
                            .map((item, index) => (
                              <>
                                <li
                                  onClick={() => selectVehicleYear(item)}
                                  value={item}
                                  id={"yrr" + index}
                                  name="CarYear"
                                >
                                  <label
                                    className="illnessbox"
                                    htmlFor={"yrr" + index}
                                  >
                                    {item}
                                    <input type="radio" name="radio" />
                                    <span className="illcheckmark"></span>
                                  </label>
                                </li>
                              </>
                            ))}
                        </ul>
                      </div>
                      <button
                        type="submit"
                        // disabled={apiRequestQQ.RegistrationYear == ""}
                        className="btn btn-primary"
                        onClick={handleNextClick}
                      >
                        Previous
                      </button>
                    </div>
                  </div>
                )}
                {activeStep === 7 && (
                  <form onSubmit={handleSubmit1(submitUserDetails)}>
                    <div
                      className={`tab-pane fade ${
                        activeStep === 7 ? "show active" : ""
                      }`}
                      id="step7"
                      role="tabpanel"
                      aria-labelledby="step7-tab"
                    >
                      <div>
                        <div className="selectcitybike">
                          <h2>Fill your Personal Details</h2>
                          <p className="fs-5">
                            (Just one more step for your prefered quote)
                          </p>
                        </div>

                        <div className="twoinsuranceform">
                          <input
                            onKeyPress={handleKeyPress}
                            type="text"
                            className="form-control fs-2"
                            maxLength={30}
                            {...register1("fullname", {
                              required: "Full Name is required",
                              pattern: {
                                value: /^[A-Za-z ]+$/, // This pattern ensures only alphabetic characters are allowed.
                                message: "Name should contain only alphabets",
                              },
                              maxLength: {
                                value: 29,
                                message: "Maximum character limit is 30",
                              },
                            })}
                            id="mname"
                            placeholder="Enter Your Name"
                          />
                          <label htmlFor="mname" className="d-none">
                            Enter Your Name
                          </label>
                          <p
                            className=" f-error fs-4"
                            style={{
                              position: "relative",
                              bottom: "1.5rem",
                              left: "0.5rem",
                            }}
                          >
                            {errors1?.fullname?.message}
                          </p>

                          <input
                            type="text"
                            className="form-control fs-2"
                            id="emailuser"
                            maxLength={50}
                            {...register1("Email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Invalid email address",
                              },
                              maxLength: {
                                value: 49,
                                message: "Maximum character limit is 50",
                              },
                            })}
                            placeholder="Enter Your Email"
                          />
                          <label htmlFor="emailuser" className="d-none">
                            Enter Your Email
                          </label>
                          <p
                            className=" f-error fs-4"
                            style={{
                              position: "relative",
                              bottom: "1.5rem",
                              left: "0.5rem",
                            }}
                          >
                            {errors1?.Email?.message}
                          </p>

                          <input
                          onKeyPress={handleKeyPress2}
                            type="text"
                            className="form-control fs-3"
                            {...register1("MobileNumber", {
                              required: "Mobile is required",
                              pattern: {
                                value:
                                  /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
                                message: "Invalid Mobile Number",
                              },
                            })}
                            id="mmobie"
                            placeholder="Enter Your Mobile Number"
                            maxLength="10"
                          />
                          <label htmlFor="vinpuut" className="d-none">
                            Enter Your Mobile Number
                          </label>
                          <p
                            className="f-error fs-3"
                            style={{
                              position: "relative",
                              bottom: "1.5rem",
                              left: "0.5rem",
                            }}
                          >
                            {errors1?.MobileNumber?.message}
                          </p>
                          {/* {tncError && (
                            <p
                              className="f-error fs-3"
                              style={{
                                position: "relative",
                                bottom: "1.5rem",
                                left: "0.5rem",
                              }}
                            >
                              Please accept Terms and Conditions
                            </p>
                          )} */}

                          {/* <p className="m-0 f-error">Input invalid</p> */}
                        </div>

                        {/* <div className="declare">

                          <label
                            className="declarebox fs-2"
                          >
                            Check this to accept
                            <Link
                              to={"/privacyandcondition"}
                              target="blank"
                              className="mx-2"
                            >
                              Terms and Conditions
                            </Link>
                            and
                            <Link
                              to={"/privacyandcondition"}
                              // href="#"
                              target="blank"
                              className="mx-2"
                            >
                              Privacy Policy
                            </Link>
                            <input
                              type="checkbox"
                              style={{ marginRight: "0.5rem" }}
                              checked={termsAccepted}
                              onChange={() => {
                                setTermsAccepted(!termsAccepted);
                                setTncError(false);
                              }}
                              id="termsCheckbox"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div> */}

                        <button
                          type="submit"
                          className="btn btn-primary mt-5 ms-5"
                          onClick={handleNextClick}
                        >
                          Previous
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary text-center mt-5 ms-4 "
                        >
                          Get Your Quote
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                {activeStep === 8 && (
                  <div
                    className={`tab-pane fade ${
                      activeStep === 8 ? "show active" : ""
                    }`}
                    id="step8"
                    role="tabpanel"
                    aria-labelledby="step8-tab"
                  >
                    <div>
                      <div className="selectcitybike">
                        <h2>Fill Vehicle and Personal Policy Details</h2>
                      </div>

                      <form onSubmit={handleSubmit2(submitPolicyDetails)}>
                        {/* <QuotePolicyDetails
                          regYear={regYear}
                          isNewVehicle={isNewVehicle}
                          insurerData={insurerData}
                          submitPolicyDetails={(data) =>
                            submitPolicyDetails(data)
                          }
                        /> */}
                        <div className="twoinsuranceform row m-0 pe-lg-5">
                          {isNewVehicle === false && (
                            <div className="col-lg-6 col-md-6 mt-3 ps-lg-0 pb-4">
                              <div className="form-floating position-relative undefined">
                                <select
                                  name="PolicyStatus"
                                  {...register2("PolicyStatus", {
                                    required: "Field is required",
                                  })}
                                  className="form-select fs-3"
                                  id="idxx0"
                                >
                                  <option value="">Select</option>
                                  <option value="continue">Not Expired</option>
                                  <option value="expired within 90 day">
                                    Expired Within 90 days
                                  </option>
                                  <option value="expired above 90 day">
                                    Expired Above 90 days
                                  </option>
                                  <option value="false">
                                    No Information About Previous Policy
                                  </option>
                                </select>
                                <label
                                  htmlFor="idxx0"
                                  className="d-block w-100"
                                >
                                  Select Policy Status
                                </label>
                              </div>
                              <p
                                className="f-error fs-4"
                                style={{
                                  position: "relative",
                                  left: "0.5rem",
                                }}
                              >
                                {errors2?.PolicyStatus?.message}
                              </p>
                            </div>
                          )}

                          <div className="col-lg-6 col-md-6 mt-3 pe-lg-0 pb-4">
                            <div className="form-floating position-relative undefined">
                              <select
                                name="NewPolicyType"
                                {...register2("NewPolicyType", {
                                  required: "Field is required",
                                })}
                                className="form-select fs-3"
                                id="idxx1"
                              >
                                <option value="">Select</option>
                                {apiRequestQQ.IsVehicleNew == true ? (
                                  <option selected value="Bundled">
                                    Bundled
                                  </option>
                                ) : apiRequestQQ.VehicleType === "4w" ? (
                                  yearDiff < 3 ? (
                                    <>
                                      <option value="ODOnly">ODOnly</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="Comprehensive">
                                        Comprehensive
                                      </option>
                                      <option value="ThirdParty">
                                        ThirdParty
                                      </option>
                                    </>
                                  )
                                ) : apiRequestQQ.VehicleType === "2w" ? (
                                  yearDiff < 5 ? (
                                    <>
                                      <option value="ODOnly">ODOnly</option>
                                    </>
                                  ) : (
                                    <>
                                      <option value="Comprehensive">
                                        Comprehensive
                                      </option>
                                      <option value="ThirdParty">
                                        ThirdParty
                                      </option>
                                    </>
                                  )
                                ) : (
                                  <>
                                    <option value="Comprehensive">
                                      Comprehensive
                                    </option>
                                    <option value="ThirdParty">
                                      ThirdParty
                                    </option>
                                    <option value="ODOnly">ODOnly</option>
                                  </>
                                )}
                              </select>
                              <label htmlFor="idxx1" className="d-block w-100">
                                New policy type?
                              </label>
                            </div>
                            <p className="f-error fs-4 mx-2">
                              {errors2?.NewPolicyType?.message}
                            </p>
                          </div>
                          {isNewVehicle === false && (
                            <>
                              {previousPolicyKnow.current !== "false" && (
                                <div className="col-lg-6 col-md-6 mt-3 ps-lg-0 pb-4">
                                  <div className="form-floating position-relative undefined">
                                    <select
                                      name="PreviousPolicyType"
                                      {...register2("PreviousPolicyType", {
                                        required: "Field is required",
                                      })}
                                      className="form-select fs-3"
                                      id="idxx2"
                                    >
                                      <option value="">Select</option>
                                      {apiRequestQQ.VehicleType === "2w" ? (
                                        yearDiff === 1 ? (
                                          <>
                                            <option value="Bundled">
                                              Bundled
                                            </option>
                                          </>
                                        ) : yearDiff > 1 && yearDiff < 5 ? (
                                          <>
                                            <option value="ODOnly">
                                              ODOnly
                                            </option>
                                          </>
                                        ) : (
                                          <>
                                            <option value="Comprehensive">
                                              Comprehensive
                                            </option>
                                            <option value="ThirdParty">
                                              ThirdParty
                                            </option>
                                          </>
                                        )
                                      ) : apiRequestQQ.VehicleType === "4w" ? (
                                        yearDiff === 1 ? (
                                          <>
                                            <option value="Bundled">
                                              Bundled
                                            </option>
                                          </>
                                        ) : yearDiff > 1 && yearDiff < 4 ? (
                                          <>
                                            <option value="ODOnly">
                                              ODOnly
                                            </option>
                                          </>
                                        ) : yearDiff >= 3 ? (
                                          <>
                                            <option value="Comprehensive">
                                              Comprehensive
                                            </option>
                                            <option value="ThirdParty">
                                              ThirdParty
                                            </option>
                                          </>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        <>
                                          <option value="Comprehensive">
                                            Comprehensive
                                          </option>
                                          <option value="ThirdParty">
                                            ThirdParty
                                          </option>
                                          <option value="Bundled">
                                            Bundled
                                          </option>
                                          <option value="ODOnly">ODOnly</option>
                                        </>
                                      )}
                                    </select>
                                    <label
                                      htmlFor="idxx2"
                                      className="d-block w-100"
                                    >
                                      Previous policy type?
                                    </label>
                                  </div>

                                  <p className="f-error fs-4 mx-2">
                                    {errors2?.PreviousPolicyType?.message}
                                  </p>
                                </div>
                              )}
                              {previousPolicyKnow.current != "false" && (
                                <div className="col-lg-6 col-md-6 mt-3 pe-lg-0 pb-4">
                                  <div className="form-floating position-relative undefined">
                                    <select
                                      name="PreInsurerCode"
                                      {...register2("PreInsurerCode", {
                                        required: "Field is required",
                                      })}
                                      className="form-select fs-3"
                                      id="idxx3"
                                    >
                                      <option value="">Select</option>
                                      {insurerData &&
                                        insurerData.length > 0 &&
                                        insurerData.map((item, i) => (
                                          <option key={i} value={item.value}>
                                            {item.option}
                                          </option>
                                        ))}
                                    </select>
                                    <label
                                      htmlFor="idxx3"
                                      className="d-block w-100"
                                    >
                                      Previous Policy Insurer
                                    </label>
                                  </div>
                                  <p className="f-error fs-4 mx-2">
                                    {errors2?.PreInsurerCode?.message}
                                  </p>
                                </div>
                              )}
                            </>
                          )}

                          <div
                            className={
                              isNewVehicle === false
                                ? "col-lg-6 col-md-6 mt-3 pb-4 ps-lg-0"
                                : "col-lg-6 col-md-6 mt-3 pb-4 pe-lg-0"
                            }
                            style={{ maxHeight: "77px" }}
                          >
                            <div className="form-floating position-relative undefined">
                              <input
                                {...register2("ManufaturingDate", {
                                  required: "Field is required",
                                })}
                                name="ManufaturingDate"
                                type="month"
                                id="idxx4"
                                className=" form-control w-100 fs-3"
                                defaultValue={moment()
                                  .startOf("month")
                                  .format(`${regYear}-MM`)}
                              />
                              <label className="w-100" htmlFor="idxx4">
                                Mfg. year
                              </label>
                            </div>

                            {/* <div className="form-floating position-relative undefined">
                        <select
                          {...register2("ManufacturingYear", {
                            required: "Year is required",
                          })}
                          id="ManufacturingYear"
                          defaultValue={new Date().getFullYear()} // Set default year to current year
                          className="form-control w-100 fs-3"
                        >
                          {geenrateMfgYears().map((year, index) => (
                            <option key={index}>{year}</option>
                          ))}
                        </select>
                        <label className="w-100" htmlFor="idxx4">Manufacturing Year</label>

                        {errors2?.ManufacturingYear && (
                          <div>
                            <span className="text-danger error-input">
                              {errors2?.ManufacturingYear?.message}
                            </span>
                          </div>
                        )}
                      </div> */}

                            <p className="f-error fs-4">
                              {errors2?.ManufaturingDate?.message}
                            </p>
                          </div>
                          <div
                            className="col-lg-6 col-md-6 mt-3 pe-lg-0 pb-4"
                            style={{ maxHeight: "77px" }}
                          >
                            <div className="form-floating position-relative undefined">
                              <input
                                {...register2("RegistrationDate", {
                                  required: "Field is required",
                                })}
                                name="RegistrationDate"
                                type="date"
                                id="idxx5"
                                className="datepicker form-control w-100 fs-3"
                                defaultValue={
                                  isNewVehicle === false
                                    ? moment().format(`${regYear}-MM-DD`)
                                    : isNewVehicle === true
                                    ? moment().format(`${regYear}-MM-DD`)
                                    : ""
                                }
                                min={ManufaturingDate.current}
                                max={moment(
                                  ManufaturingDate.current,
                                  "YYYY-MM-DD"
                                )
                                  .add(1, "year")
                                  .format("YYYY-MM-DD")}
                              />
                              <label className="w-100" htmlFor="idxx5">
                                Reg. date of your car
                              </label>
                            </div>
                            <p className="f-error fs-4 mx-2">
                              {errors2?.RegistrationDate?.message}
                            </p>
                          </div>
                          {isNewVehicle == false &&
                            previousPolicyKnow.current != "false" && (
                              <div
                                className="col-lg-6 co-md-6 mt-3 ps-lg-0"
                                style={{ maxHeight: "77px" }}
                              >
                                <div className="form-floating position-relative undefined">
                                  <input
                                    {...register2("PrePolicyEndDate", {
                                      required: "Field is required",
                                    })}
                                    name="PrePolicyEndDate"
                                    type="date"
                                    min={minDate}
                                    max={maxDate}
                                    id="idxx6"
                                    className="datepicker form-control w-100 fs-3"
                                    defaultValue
                                  />
                                  <label className="w-100" htmlFor="idxx6">
                                    Prev. year policy expiry date
                                  </label>
                                </div>
                                <p
                                  className="f-error fs-4 mx-2"
                                  style={{
                                    position: "relative",
                                    bottom: "2rem",
                                    // left: "1rem",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  {errors2?.PrePolicyEndDate?.message}
                                </p>
                              </div>
                            )}

                          <div
                            className="col-lg-6 col-md-6 mt-3 pe-lg-0 pb-4"
                            style={{ maxHeight: "77px" }}
                          >
                            <div className="form-floating position-relative undefined">
                              <select
                                name="CustomerType"
                                {...register2("CustomerType", {
                                  required: "Field is required",
                                })}
                                className="form-select fs-3"
                                id="idxx7"
                              >
                                <option value="">Select</option>
                                <option value="INDIVIDUAL">Individual</option>
                                <option value="COMPANY">Organization</option>
                              </select>
                              <label htmlFor="idxx7" className="d-block w-100">
                                Vehicle Owned By
                              </label>
                            </div>
                            <p className="f-error fs-4 mx-2">
                              {errors2?.CustomerType?.message}
                            </p>
                          </div>
                          {isNewVehicle === false &&
                            previousPolicyKnow.current != "false" && (
                              <>
                                <div
                                  className="col-lg-6 col-md-6 mt-3 ps-lg-0 pb-4"
                                  style={{ maxHeight: "77px" }}
                                >
                                  <div className="form-floating position-relative undefined">
                                    <select
                                      name="PreviousNoClaimBonus"
                                      {...register2("PreviousNoClaimBonus", {
                                        required: "Field is required",
                                      })}
                                      className="form-select fs-3"
                                      id="idxx8"
                                    >
                                      <option value="">Select</option>
                                      <option value={0}>0%</option>
                                      <option value={20}>20%</option>
                                      <option value={25}>25%</option>
                                      <option value={35}>35%</option>
                                      <option value={45}>45%</option>
                                      <option value={50}>50%</option>
                                    </select>
                                    <label
                                      htmlFor="idxx8"
                                      className="d-block w-100"
                                    >
                                      Previous No Claim Bonus (NCB)
                                    </label>
                                  </div>
                                  <p className="f-error fs-4 mx-2">
                                    {errors2?.PreviousNoClaimBonus?.message}
                                  </p>
                                </div>
                                <div
                                  className="col-lg-6 col-md-6 mt-3 pe-lg-0 pb-4"
                                  sstyle={{ maxHeight: "77px" }}
                                >
                                  <div className="form-floating position-relative undefined">
                                    <select
                                      {...register2(
                                        "PreviousInsuranceClaimed",
                                        {
                                          required: "Field is required",
                                        }
                                      )}
                                      name="PreviousInsuranceClaimed"
                                      className="form-select fs-3"
                                      id="idxx9"
                                    >
                                      <option value="">Select</option>
                                      <option value="true">Yes</option>
                                      <option value="false">No</option>
                                    </select>
                                    <label
                                      htmlFor="idxx9"
                                      className="d-block w-100"
                                    >
                                      Claim made in expiring policy?
                                    </label>
                                  </div>
                                  <p className="f-error fs-4 mx-2">
                                    {errors2?.PreviousInsuranceClaimed?.message}
                                  </p>
                                </div>
                              </>
                            )}

                          <div className="col-12">
                            <div className="form-btns d-flex justify-content-center align-items-center mt-3 w-100">
                              <button
                                type="submit"
                                className="btn btn-primary fs-2 px-5 py-3 my-lg-0 my-md-3  my-5 mr-2 ms-5"
                                onClick={handleNextClick}
                              >
                                Previous
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary fs-2 ms-5 px-5 py-3 my-lg-0 my-md-3  my-5 mr-2"
                              >
                                Get Preferred Quote
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <WhyExpertCoverBuy />
        {vehicleType == "4w" ? (
          <CarInsuranceDetails />
        ) : vehicleType == "2w" ? (
          <BikeInsuranceDetails />
        ) : vehicleType == "pcv" ? (
          <TaxiInsuranceDetails />
        ) : (
          vehicleType == "gcv" && "GCV"
        )}{" "}
        <Footer />
      </div>
    </div>
  );
};
