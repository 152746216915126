import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import CopyLinkComponent from "./CopyLinkComponent";
// import { generateSms, sendEmail } from "../services/TPApiCall";
import { generateSms, sendEmail } from "./TPApiCall"
import { toast } from "react-toastify";

const SharePaymentQuotationModal = ({
  toggleSharePaymentModal,
  sharePaymentModal,
  quotationPage,
  generatePdf,
}) => {
  const motorRequest = useSelector((state) => state?.root?.motorRequest);
  const selectedPlan = useSelector((state) => state?.root?.selectedPlan);

  const [shareMobile, setShareMobile] = useState(motorRequest?.mobileNumber);
  const [shareEmail, setShareEmail] = useState(motorRequest?.email);
  const [shareMode, setShareMode] = useState("Email");
  const [sendButtonLoader, setSendButtonLoader] = useState(false);

  // useEffect(() => {
  //   smsText();
  // }, []);

  const handleChangeShareMobileEmail = (val) => {
    shareMode === "SMS" ? setShareMobile(val) : setShareEmail(val);
  };

  useEffect(() => {
    shareMode === "SMS"
      ? setShareMobile(motorRequest?.mobileNumber)
      : setShareEmail(motorRequest?.email);
  }, [shareMode]);

  const handleSendButton = async () => {
    setSendButtonLoader(true);
    try {
      let response;
      if (shareMode === "Email") {
        const postData = {
          emailId: shareEmail,
          caseId: motorRequest?.caseId,
          customerLeadId: motorRequest?.customerLeadId,
          mailType: quotationPage ? "quoteShare" : "paymentLinkShare",
          customerName: motorRequest?.firstName + " " + motorRequest?.lastName,
        };
        response = await sendEmail(postData);
      } else {
        const postData = {
          mobileNo: shareMobile,
          caseId: motorRequest?.caseId,
          customerLeadId: motorRequest?.customerLeadId,
          smsType: quotationPage ? "quoteShare" : "paymentLinkShare",
          customerName: motorRequest?.firstName + " " + motorRequest?.lastName,
        };
        response = await generateSms(postData);
      }
      if (response?.status === "success") {
        toast.success(response?.data?.message || "Message Sent", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setSendButtonLoader(false);
        toggleSharePaymentModal();
      } else {
        setSendButtonLoader(false);
        toast.error(
          response?.errors
            ? response?.errors[0]?.displayMessage
            : response?.data?.message || "Error while Sending Message",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    } catch (error) {
      setSendButtonLoader(false);
    }
  };

  return (
    <Modal
      isOpen={sharePaymentModal}
      toggle={toggleSharePaymentModal}
      size="md"
      // centered
      backdrop="static"
    >
      <ModalBody>
        <div className="vehicle_modal">
          <div className="modal-logo">
            <a>
              <img src="/assets/images/ex_new_2.png"></img>
            </a>
          </div>
          <div className="modal_heading w-100 mb-4">
            <h4 className="text-center">
              Share {quotationPage ? "Quotation" : "Payment"} Link{" "}
              <CopyLinkComponent
                link={
                  motorRequest?.paymentUrl
                    ? motorRequest?.paymentUrl
                    : window.location.href
                }
              />
            </h4>
            <div onClick={toggleSharePaymentModal} class="v2_close false">
              <a>
                <img
                  src="/assets/images/delete_icon.svg"
                  title="Close"
                  alt="close"
                />
              </a>
            </div>
          </div>{" "}
          <div
            className="nav nav-pills mb-3 mt-3 tab_owner_tab"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
                onClick={() => {
                  setShareMode("Email");
                }}
              >
                Email
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
                onClick={() => {
                  setShareMode("SMS");
                }}
              >
                SMS
              </button>
            </li>
          </div>
          <div className="d-flex flex-column">
            <div className=" mb-2">
              <div className="onwer-details">
                <div className="did-floating-label-content">
                  <input
                    type={shareMode === "SMS" ? "text" : "email"}
                    className=" did-floating-input"
                    placeholder=""
                    value={shareMode === "SMS" ? shareMobile : shareEmail}
                    name={shareMode === "SMS" ? "mobileNumber" : "email"}
                    onChange={(e) =>
                      handleChangeShareMobileEmail(e.target.value)
                    }
                  />
                  <label htmlFor="idxx4" className="did-floating-label">
                    {shareMode === "SMS" ? "Mobile No." : "Email"}
                    <span className="text-danger">*</span>
                  </label>
                </div>
              </div>
            </div>
            <div className=" mb-2">
              {/* <div className="onwer-details">
                <div className="did-floating-label-content">
                  <textarea
                    type="text"
                    className=" did-floating-input h-auto"
                    placeholder=""
                    name="sms"
                    rows="5"
                    cols="10"
                    disabled
                    defaultValue={shareLinkText}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div
            className={`d-flex ${
              quotationPage
                ? "justify-content-around"
                : "justify-content-center"
            }`}
          >
            <button className="mismatch-btn" onClick={handleSendButton}>
              {sendButtonLoader ? (
                <div class="spinner-border text-light" role="status"></div>
              ) : (
                "Send"
              )}
            </button>
            {quotationPage && (
              <button className="mismatch-btn" onClick={generatePdf}>
                Download Quotation
              </button>
            )}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SharePaymentQuotationModal;
