import {
  API_ERROR_RESPONSE,
  INSPECTION_QUICK_QUOTE,
  LOGGED_USER_DETAILS,
  QUICK_QUOTE_PAYLOAD,
  QUICK_QUOTE_RESULTS,
  REMOVE_ERROR_MESSAGE,
  RESET_QUICK_QUOTE_PAYLOAD,
  RESET_QUICK_QUOTE_RESULTS,
  RESET_SELECTED_PLAN,
  SELECTED_PLAN,
  MOTOR_QUOTE_PAYLOAD,
  RESET_MOTOR_QUOTE_PAYLOAD,
  KYC_CONFIG,
  RESET_KYC_CONFIG,
} from "../types";
import store from "../index";

export const saveUserDetails = (payload) => {
  return {
    type: LOGGED_USER_DETAILS,
    payload,
  };
};

export const resetUserDetails = (payload) => {
  return {
    type: RESET_USER_DETAILS,
    payload,
  };
};

export const saveUserData = (payload) => {
  console.log("PAYLOAD", payload);
  return {
    type: USER_DATA,
    payload,
  };
};

export const resetUserData = (payload) => {
  return {
    type: RESET_USER_DATA,
    payload,
  };
};

export const quickQuotePayload = (payload) => {
  return {
    type: QUICK_QUOTE_PAYLOAD,
    payload,
  };
};

export const motorQuotePayload = (payload) => {
  return {
    type: MOTOR_QUOTE_PAYLOAD,
    payload,
  };
};

export const quickQuoteResult = (payload) => {
  return {
    type: QUICK_QUOTE_RESULTS,
    payload,
  };
};

export const apiErrorReponse = (payload) => {
  return {
    type: API_ERROR_RESPONSE,
    payload,
  };
};

export const removeApiErrorMessage = (payload) => {
  return {
    type: REMOVE_ERROR_MESSAGE,
    payload,
  };
};

export const selectedPlanAction = (payload) => {
  return {
    type: SELECTED_PLAN,
    payload,
  };
};

export const resetapiRequestQQ = (payload) => {
  return {
    type: RESET_QUICK_QUOTE_PAYLOAD,
    payload,
  };
};

export const resetMotorRequest = (payload) => {
  return {
    type: RESET_MOTOR_QUOTE_PAYLOAD,
    payload,
  };
};

export const resetSelectedPlan = () => {
  return {
    type: RESET_SELECTED_PLAN,
  };
};

export const resetQuickQuoteResults = () => {
  return {
    type: RESET_QUICK_QUOTE_RESULTS,
  };
};

export const inspectionQuickQuoteSet = (payload) => {
  return {
    type: INSPECTION_QUICK_QUOTE,
    payload,
  };
};

export const kycConfig = (payload) => {
  return {
    type: KYC_CONFIG,
    payload,
  };
};

export const resetKycConfig = (payload) => {
  return {
    type: RESET_KYC_CONFIG,
    payload,
  };
};

export const dispatchQuickQuote = (key, value) => {
  console.log("DISpaTVH", key, value);
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(quickQuotePayload(obj));
};

export const dispatchMotorQuote = (key, value) => {
  let obj = {
    key: key,
    value: value,
  };
  store.dispatch(motorQuotePayload(obj));
};

export const saveApiErrorReponse = (api, error, image) => {
  store.dispatch(
    apiErrorReponse({
      Api_name: api,
      error: error,
      image: image,
    })
  );
};

export const dispatchKycConfig = (value) => {
  console.log("dispatchKycConfig", value);
  // let obj = {
  //   [key]: value,
  // };
  store.dispatch(kycConfig(value));
};

export const dispatchResetKycConfig = (value) => {
  // let obj = {
  //   [key]: value,
  // };
  store.dispatch(kycConfig(value));
};

export const dispatchResetQuickQuote = (key, value) => {
  // debugger
  console.log('posId',key)
  console.log('posId',value)
  let obj = {
    isFiltered: false,
    posId: value,
    VehicleType: "",
    CustomerType: "",
    IsPreviousInsurerKnown: true,
    isPrevEngineProtection: true,
    isPrevTyreProtection: true,
    isPrevZeroDepreciationCover: true,
    isPrevInvoiceCover: true,
    Salutation: "Mr",
    RegistrationDate: "",
    RegistrationNumber: "",
    PolicyEndDate: "",
    PolicyStartDate: "",
    MagmaProposalNumber: "",
    PolicyIssueDate: "",
    SuccessURL: "",
    FailureURL: "",
    PreInsurerCode: "",
    PrePolicyNumber: "",
    ProductCode: "",
    ManufaturingDate: "",
    ProposalId: "",
    VariantCode: {
      Digit: "",
      Bajaj: "",
      HDFC: "",
      Shriram: "",
      Kotak: "",
      Reliance: "",
      Future: "",
      Royal: "",
      Zuno: "",
    },
    PAChecBox: {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
    },
    ApiUniqueNumber: {
      Digit: "",
      Bajaj: "",
      HDFC: "",
      Shriram: "",
      Kotak: "",
      Reliance: "",
      Future: "",
      Royal: "",
      Magma: "",
      Zuno: "",
    },
    Email: "",
    FirstName: "",
    LastName: "",
    MiddleName: "",
    MobileNumber: "",
    FlatNumber: "",
    StreetNumber: "",
    Street: "",
    District: "",
    City: "",
    State: "",
    StateCode: "",
    Country: "",
    Pincode: "",
    PermanentAddress: "",
    Dob: "",
    Gender: "",
    PrePolicyEndDate: "",
    MakeName: "",
    ModelName: "",
    VariantName: "",
    PolicyType: "Renewal",
    RegistrationYear: "",
    PreviousPolicyType: "",
    NewPolicyType: "",
    FuelType: "",
    customerId: "",
    ApiId: "",
    policyId: "",
    RtoCode: "",
    EngineNumber: "",
    ChassisNumber: "",
    NomineeFirstName: "",
    NomineeLastName: "",
    NomineeDateOfBirth: "",
    NomineeRelationship: "",
    PreviousInsuranceClaimed: false,
    Idv: 0,
    PreviousNoClaimBonus: "0",
    PersonalAccident: {
      Selection: false,
      InsuredAmount: false,
      CoverTerm: false,
    },
    AddOns: {
      RoadSideAssistance: false,
      EngineProtection: false,
      TyreProtection: false,
      RimProtection: false,
      Consumables: false,
      IsElectricalAccessories: false,
      IsNonElectricalAccessories: false,
      IsCngAccessories: false,
      PersonalAccident: false,
      InvoiceCover: false,
      EngineGearBox: false,
      NCBProtection: false,
      VoluntaryDeductive: false,
      PassengerCover: false,
      LossOfPersonalBelongings: false,
      ZeroDepriciationCover: false,
      KeyReplacement: false,
      LiabilitiesToPaidDriver: false,
      ElectricAmount: 0,
      NonElectricAmount: 0,
      CNGValue: 0,
      PassengerCoverAmount: 0,
      VoluntaryDeductiveAmount: 0,
      discount: 0,
      NoOfLegelDrives: 0,
    },
    PaymentAmount: 0,
    PaymentId: "",
    CubicCapacity: "",
    CarryingCapacity: "",
    VehicleMakeCode: "",
    VehicleModelCode: "",
    VehicleSubTypeCode: "",
    RegistrationCity: "",
    IsVehicleNew: false,
    applicationId: "",
    PolicyStatus: "continue",
    PanNumber: "",
    PanImage: "",
    AdharImage: "",
    AdharImageBack: "",
    LeadID:"",
    quoteNo : "",
    quoteOptionNo:"",
    KYCReqNo:"",
    zunoPremium : 0,
    KYCNo:"",
    allowableDiscountZuno:"",
    zunoUrl : "",
    Father : "",
    Spouse: "",
    PolicyId: "",
    bpId: "",
    InstruementId: "",
    IsHypothecation: false,
    HpnBank: "",
    HpnCity: "",
    PreTPPolicyNumber: "",
    PreTPInsurerCode: "",
    PreTPPolicyEndDate: "",
    GSTNo: "",
    IncorporationDate: "",
    IsValidPuc: false,
    PUCNumber: "",
    PUCValidUpto: "",
    RelianceRTOCode: "",
    IsInternalCNG: false,
    KYC: {
      HDFC: "",
      Future: "",
      Reliance: "",
      Kotak: "",
      RoyalRefNo: "",
      Royal: "",
      Shriram: "",
      AadharNo: "",
      FatherName: "",
      MotherName: "",
      AadharBackPic: "",
      PassportPic: "",
    },
    idvRangeSet: false,
  };
  store.dispatch(resetapiRequestQQ(obj));
};

export const dispatchResetMotorQuote = (key, value) => {
  let obj = {
    posId: "",
    customerLeadId: "",
    rId: "",
    paymentUrl: "",
    source: "customer",
    salutation : "",
    makeName: "",
    makeId: "",
    modelName: "",
    modelId: "",
    variantName: "",
    variantId: "",
    fuelType: "",
    startingSource: "",
    emplId: "",
    vehicleType: "",
    vehicleSubType: "",
    newPolicyType: "",
    quotationNumber: "",
    salutation: "",
    email: "",
    firstName: "",
    lastName: "",
    middleName: "",
    mobileNumber: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    cityId: "",
    stateId: "",
    pincode: "",
    isRcAddressSame: "",
    rcAddressLine1: "",
    rcAddressLine2: "",
    rcAddressLine3: "",
    roadSideAssistance: false,
    engineProtection: false,
    tyreProtection: false,
    consumables: false,
    isElectricalAccessories: false,
    isNonElectricalAccessories: false,
    isCngAccessories: false,
    cngValue: "",
    cngKitType: "",
    isTppdDiscount: false,
    personalAccident: false,
    invoiceCover: false,
    ncbProtection: false,
    isVoluntaryDeductable: false,
    rimProtection: false,
    voluntaryDeductableAmount: "",
    lossOfPersonalBelongings: false,
    zeroDepriciationCover: false,
    keyReplacement: false,
    electricAmount: "",
    nonElectricAmount: "",
    passengerCoverAmount: "",
    isPassengerCover: false,
    liabilitiesToPaidDriver: false,
    noOfPaidDrivers: "",
    isHypothecation: false,
    hpnCity: "",
    hpnBank: "",
    hpnBankCode: "",
    customerType: "",
    planId: "",
    isOwnerChanged: false,
    prevPolicyExpiryStatus: "",
    businessType: "",
    insurerCode: "",
    isPreviousInsuranceClaimed: false,
    prevPolicyEndDate: "",
    prevPolicyNumber: "",
    prevNoClaimBonus: 0,
    currentNoClaimBonus: '',
    prevPolicyType: "",
    prevTPInsurerCode: "",
    prevTPPolicyNumber: "",
    prevTPPolicyEndDate: "",
    previousPolicyDetails: "",
    previousTpPolicyDetails: "",
    rtoCode: "",
    manufaturingDate: "",
    registrationDate: "",
    registrationNumber: "",
    variantCode: "",
    idv: 0,
    engineNumber: "",
    chassisNumber: "",
    isValidPuc: false,
    pucNumber: "",
    pucValidUpto: "",
    nomineeName: "",
    nomineeDob: "",
    nomineeRelation: "",
    appointeeName: "",
    appointeeDob: "",
    appointeeRelation: "",
    reasonForNotOptingPersonalAccident:false,
    isPrevInvoiceCover: true,
    isPrevZeroDepreciationCover: true,
    isPrevEngineProtection: true,
    isPrevTyreProtection: true,
    nomineeRelationCode : '',
    caseId: "",
    dob: "",
    gender: "",
    maritalStatus: "",
    docName: "",
    docNumber: "",
    kycStatus: "",
    kycType: "",
    city: "",
    state: "",
    kycData: {},
    isVehicleNew: false,
  };
  store.dispatch(resetMotorRequest(obj));
};

export function generateArrayOfYears() {
  var max = new Date().getFullYear();
  var min = max - 18;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  return years;
}

export function generateArrayOfYearsForNew() {
  var max = new Date().getFullYear();
  var min = max - 1;
  var years = [];

  for (var i = max; i >= min; i--) {
    years.push(i);
  }
  console.log("YEARS", years);
  return years;
}
