import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  getVehicleMake,
  getVehicleModel,
  getVehicleVariant,
} from "../../../Services/masterServices";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "../../../common/Tags/ReactSelectEditVehicleDetails";

import {
  dispatchQuickQuote,
  dispatchMotorQuote,
  resetQuickQuoteResults,
} from "../../../../store/actions/userActions";
import {
  FutureGeneralQuickQuote,
  HDFCCalculatePremium,
  KotakAPICall,
  RelianceAPICall,
  bajajApiCall,
  digitAPICall,
  getZunoQuickQuote,
  magmaAPICall,
  getMotorQuote
} from "../../../common/Homepage/TPApiCall";
import { motorQuotationObj } from "../../../../store/reducers/userReducers";
const EditVehicleDetails = ({ show, setModal }) => {
  const toggle = () => setModal(!show);
  const [makeData, setMakeData] = React.useState([]);
  const [newFilter, setNewFilter] = useState({});
  const [modelData, setModelData] = React.useState([]);
  const [variantData, setVariantData] = React.useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const motorRequest  = useSelector((state) => state.root.motorRequest);
  const [dataMotorQuotation, setDataMotorQuotation] = useState({});
  const dispatch = useDispatch();
  // const [manufacturingDate, setManufacturingDate] = useState();
  // const [registrationDate, setRegistrationDate] = useState();
  // const [vehicleModel, setVehicleModel] = React.useState("");

  const vehicleModel =
    apiRequestQQ.VehicleType === "4w"
      ? "Pvt Car"
      : apiRequestQQ.VehicleType === "2w"
      ? "MotorBike"
      : apiRequestQQ.VehicleType === "gcv"
      ? "Goods Carrying"
      : apiRequestQQ.VehicleType === "pcv" && "Passenger Carrying";
  useEffect(() => {
    let arr = [];
    getVehicleMake({ Vehicle_Type: vehicleModel }).then((response) => {
      if (response.status === true) {
        let data = response.data;
        let i = 0;
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.Make,
            value: item.Make,
          });
          i++;
        }
        setMakeData(arr);
      }
    });
    getVehicleModel({
      make: apiRequestQQ?.MakeName,
      Vehicle_Type: vehicleModel,
    }).then((response) => {
      if (response.status === true) {
        // handleStepSelection(activeStep + 1);
        let data = response.data;
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.Model,
            value: item.Model,
          });
          i++;
        }
        console.log("MODEL DATA", arr);
        setModelData(arr);
      }
    });
    getVehicleVariant({
      make: apiRequestQQ.MakeName,
      model: apiRequestQQ?.ModelName,
      Vehicle_Type: vehicleModel,
    }).then((response) => {
      if (response.status === true) {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.Fuel_Type === v.Fuel_Type) === i
        );
        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].Fuel_Type);
          j++;
        }
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.Variant,
            value: item.Vehicle_Code,
            Fuel_Type: item.Fuel_Type,
            Cubic_Capacity: item.Cubic_Capacity,
            Seating_Capacity: item.Seating_Capacity,
            HDFC: item.HDFC,
            Shriram: item.Shriram,
            Kotak: item.Kotak,
            Reliance: item.Reliance,
            Future: item.Future,
            Royal: item.Royal,
            Bajaj: item.Bajaj,
            Magma: item.Magma,
            Sompo: item.Universal_Sompo,
            vehicleId: item.id,
            Liberty: item.Liberty,
          });
          i++;
        }
        // console.log("arrrrrrrrrrrrr", arr);
        setVariantData(arr);

        // if (apiRequestQQ?.VehicleType === "4w") {
        //   // handleStepSelection(activeStep + 1);
        // } else {
        //   dispatchQuickQuote("FuelType", "Petrol");
        //   // handleStepSelection(activeStep + 2);
        // }
      }
    });
  }, []);
  const geenrateMfgYears = () => {
    var max = new Date().getFullYear();
    console.log("max", max);
    var min = max - 25;
    var years = [];
    for (let i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  };

  const handleSelectMake = (make) => {
    setNewFilter((prevState) => ({ ...prevState, MakeName: make }));
    // debugger;
    // dispatchQuickQuote("MakeName", make);
    // dispatchQuickQuote("ManufaturingDate" ,manufacturingDate )
    // dispatchQuickQuote("RegistrationDate" , registrationDate)
    getVehicleModel({ make: make, Vehicle_Type: vehicleModel }).then(
      (response) => {
        if (response.status === true) {
          // toggleTab(activeTab + 1);
          let data = response.data;
          let i = 0;
          let arr = [];
          while (i < data.length) {
            let item = data[i];
            arr.push({
              label: item.Model,
              value: item.Model,
            });
            i++;
          }
          setModelData(arr);
        }
      }
    );
  };

  const handleSelectModel = (model) => {
    console.log("model", model);
    // dispatchQuickQuote("ModelName", model);
    setNewFilter((prevState) => ({ ...prevState, ModelName: model }));
    getVehicleVariant({
      make: newFilter.MakeName,
      model: model,
      Vehicle_Type: vehicleModel,
    }).then((response) => {
      if (response.status === true) {
        let data = response.data;
        let fuelTypeArr = data.filter(
          (v, i, a) => a.findIndex((v2) => v2.Fuel_Type === v.Fuel_Type) === i
        );
        let j = 0;
        let fuelarr = [];
        while (j < fuelTypeArr.length) {
          fuelarr.push(fuelTypeArr[j].Fuel_Type);
          j++;
        }
        setFuelTypes(fuelarr);
        let i = 0;
        let arr = [];
        while (i < data.length) {
          let item = data[i];
          arr.push({
            label: item.Variant,
            value: item.Vehicle_Code,
            Fuel_Type: item.Fuel_Type,
            Cubic_Capacity: item.Cubic_Capacity,
            Seating_Capacity: item.Seating_Capacity,
            HDFC: item.HDFC,
            Shriram: item.Shriram,
            Kotak: item.Kotak,
            Reliance: item.Reliance,
            Future: item.Future,
            Royal: item.Royal,
            Bajaj: item.Bajaj,
            Magma: item.Magma,
            Sompo: item.Universal_Sompo,
            vehicleId: item.id,
            Liberty: item.Liberty,
          });
          i++;
        }
        console.log("Variant DAta", arr);
        setVariantData(arr);
      }
    });
  };

  const handleSelectPetrolType = (val) => {
    // dispatchQuickQuote("FuelType", val);
    setNewFilter((prevState) => ({ ...prevState, FuelType: val }));
    // toggleTab(activeTab + 1);
  };

  const handleSelectManufacturingType = (val) => {
    // debugger;
    // setManufacturingDate(val);
    // console.log("manufacturingDate", manufacturingDate);
    setNewFilter((prevState) => ({ ...prevState, ManufaturingDate: val }));
    // dispatchQuickQuote("ManufaturingDate", val);
  };

  // const handleSelectRegistrationType = (val) => {
  //   debugger;
  //   setRegistrationDate(val);
  //   console.log(registrationDate);
  //   let year = val.split('-')[0]
  //   debugger
  //   console.log('year',year)
  //   dispatchQuickQuote("RegistrationDate", val);
  //   dispatchQuickQuote("RegistrationYear", year);
  // };

  const handleSelectVariant = (variant) => {
    // dispatchQuickQuote("VariantCode.Digit", variant.value);
    // dispatchQuickQuote("VariantCode.HDFC", variant.HDFC);
    // dispatchQuickQuote("VariantCode.Shriram", variant.Shriram);

    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, Digit: variant.value },
    }));
    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, HDFC: variant.HDFC },
    }));
    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, Shriram: variant.Shriram },
    }));
    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, Kotak: variant.Kotak },
    }));
    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, Reliance: variant.Reliance },
    }));
    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, Future: variant.Future },
    }));
    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, Royal: variant.Royal },
    }));
    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, Bajaj: variant.Bajaj },
    }));
    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, Magma: variant.Magma },
    }));
    setNewFilter((prevState) => ({
      ...prevState,
      VariantCode: { ...prevState.VariantCode, vehicleId: variant.vehicleId },
    }));
    let postdata = {
      VehicleType: apiRequestQQ.VehicleType,
      Make: apiRequestQQ.MakeName,
      Model: apiRequestQQ.ModelName,
      Variant: variant.label,
      CC: variant.Cubic_Capacity,
      fuelType: variant.Fuel_Type,
    };
    setNewFilter((prevState) => ({ ...prevState, VariantName: variant.label }));
    setNewFilter((prevState) => ({
      ...prevState,
      FuelType: variant.Fuel_Type,
    }));
  };

  useEffect(() => {
    console.log("NEW FILTER", newFilter);
  }, [newFilter]);

  useEffect(() => {
    for (let key in newFilter.VariantCode) {
      console.log(`VariantCode.${key}`, newFilter.VariantCode[key]);
      dispatchQuickQuote(`VariantCode.${key}`, newFilter.VariantCode[key]);
    }
  }, [newFilter.VariantCode]);

  let calculateYearDifference = (date1, date2) => {
    // Convert strings to Date objects if they are not already
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(startDate - endDate);

    // Convert milliseconds to years (assuming 365.25 days in a year for accuracy)
    const differenceYears = differenceMs / (1000 * 60 * 60 * 24 * 365.25);

    // Round down to the nearest integer to get the whole number of years
    // const yearsDifference = Math.floor(differenceYears);

    console.log("Years Difference", differenceYears);
    return differenceYears;
    // setYearDiff[yearsDifference];
  };
  let yearDiff = calculateYearDifference(
    apiRequestQQ.RegistrationDate,
    new Date().toISOString().slice(0, 10)
  );

  // const handleUpdateMMV = () => {
  //   for (let key in newFilter) {
  //     if (
  //       key == "Digit" ||
  //       key == "Shriram" ||
  //       key == "HDFC" ||
  //       key == "Bajaj"
  //     ) {
  //       dispatchQuickQuote("VariantCode." + key, newFilter[key]);
  //     } else {
  //       dispatchQuickQuote(key, newFilter[key]);
  //     }
  //   }
  //   toggleModal(!modal);
  //   updateMMV();
  // };

  const motorQuotation = async (data) => {  
    // debugger;
    if (data) {
      let response;
      let isEqual;
      console.log("new Dataa", data);
      console.log("previous Data", dataMotorQuotation);
      let newData = { ...data };
      for (let key in data) {
        console.log("comparison", data[key], dataMotorQuotation[key]);
        if (data[key] !== dataMotorQuotation[key]) {
          isEqual = false;
          break;
        } else {
          isEqual = true;
        }
      }
      // let isEqual = compareObjects(data, dataMotorQuotation);
      console.log("isEqual");
      if (!isEqual) {
        dispatchMotorQuote("rId", "");
        newData = { ...newData, rId: "" };
      }
      console.log("isEqual", isEqual);
      setDataMotorQuotation(newData);
      let updatedData = { ...newData };
      let executionCount = 0;
      const fetchQuotesData = async () => {
        executionCount++;
        if (
          updatedData?.rId &&
          response?.caseStatus === "Quotes Generated" &&
          response?.isAllQuotesDone
        ) {
          // debugger;
          // response = await getMotorQuote(updatedData);
          getMotorQuote(updatedData).then((response) => {
            // debugger
            console.log("response", response);
            setGetMotorQuoteResponse(response);
            dispatchMotorQuote('sessionId', response?.sessionId)
            console.log("executionCount", executionCount);
            if (executionCount > 5) {

              dispatchMotorQuote('caseId',response.caseId)
              dispatchMotorQuote('planId',response.quotes[0] ? response.quotes[0].planId  : '' )
              dispatchMotorQuote('insurerId' , response.quotes[0]  ? response.quotes[0].insurerId : '')


              clearInterval(interval);
              handleQuotesData(response);
            } 
          })
         
          if (response?.quotes?.length == 0) {
            // saveApiErrorReponse(
            //   "Tata",
            //   '',
            //   "/assetss/images/TataLogo.png"
            // );
            clearInterval(interval);
          }
          console.log("response generated");
        } else if (
          updatedData?.rId &&
          response?.caseStatus === "Quotes Requested"
        ) {
          response = await getMotorQuote(updatedData);
          console.log("response requested", response);
          setGetMotorQuoteResponse(response);
          dispatchMotorQuote('sessionId', response?.sessionId)

          updatedData = { ...updatedData, rId: response?.rId };
          dispatchMotorQuote("rId", response?.rId);
        } else if (
          updatedData?.rId &&
          response?.caseStatus === "Quotes In Process" &&
          response?.isAllQuotesDone === false

        ) {
          getMotorQuote(updatedData).then((response) => {
            // debugger
            console.log("response", response);
            // setGetMotorQuoteResponse(response);
            console.log("executionCount", executionCount);
            if (executionCount > 8) {

              dispatchMotorQuote('caseId',response.caseId)
              dispatchMotorQuote('planId',response.quotes[0] ?  response.quotes[0].planId : '')
              dispatchMotorQuote('insurerId' ,response.quotes[0] ?  response.quotes[0].insurerId : '')


              clearInterval(interval);
              handleQuotesData(response);
            }
          }
        )
        }
        else {
          if(executionCount < 6){

            response = await getMotorQuote(updatedData);
            console.log("response initiated", response);
            setGetMotorQuoteResponse(response);
            dispatchMotorQuote('sessionId', response?.sessionId)
            updatedData = { ...updatedData, rId: response?.rId };
            dispatchMotorQuote("rId", response?.rId);

            

          }

          else{

          

          clearInterval(interval);
              handleQuotesData(response);
          }
         
        }
      };

      const interval = setInterval(fetchQuotesData, 2000);
    }
  };

  const handleQuotesData = (data) => {
    // debugger;
    data?.quotes?.map((item) =>
      store.dispatch(
          quickQuoteResult(
            policyResultArrNew(
              item?.insurer === 'ICICI Lombard General Insurance Co. Ltd.' && "/assetss/images/icici.jpeg" || item?.insurer === "Tata AIG General Insurance Co. Ltd." &&  "/assetss/images/TataLogo.png" || item?.insurer === 'Bajaj Allianz General Insurance Co. Ltd.' && "/assets/images/logos/partners/bajaj.png" || item?.insurer === 'HDFC' && "/assets/images/hdfcegro.jpeg" || item?.insurer === 'Zurich Kotak General Insurance Company (India) Limited' && '/assetss/images/ZK Horizontal.png' || item?.insurer === 'Future Generali India Insurance Company Limited' && '/assets/images/furturegeneral.png'  ,
              item?.insurer,
              "",
              "",
              motorRequest?.registrationNumber,
              item?.make,
              item?.model,
              item?.variant,
              item?.basicODPremium,
              item?.basicTPPremium,
              item?.netPremium,
              item?.gst,
              item?.finalPremium,
              item?.minIdv,
              item?.maxIdv,
              item?.idv,
              item?.policyStartDate,
              item?.policyEndDate,
              item?.discount,
              item?.insurer,
              item?.policyType,
              item?.roadSideAssistance?.isOpted,
              item?.roadSideAssistance?.isOpted
                ? item?.roadSideAssistance?.premium
                : item?.roadSideAssistance?.isOpted,
              item?.engineProtection?.isOpted,
              item?.engineProtection?.isOpted
                ? item?.engineProtection?.premium
                : item?.engineProtection?.isOpted,
              item?.tyreProtection?.isOpted,
              item?.tyreProtection?.isOpted
                ? item?.tyreProtection?.premium
                : item?.tyreProtection?.isOpted,
              item?.rimProtection?.isOpted,
              item?.rimProtection?.isOpted
                ? item?.rimProtection?.premium
                : item?.rimProtection?.isOpted,
              item?.consumables?.isOpted,
              item?.consumables?.isOpted
                ? item?.consumables?.premium
                : item?.consumables?.isOpted,
              item?.electricalAccessories?.isOpted,
              item?.electricalAccessories?.isOpted
                ? item?.electricalAccessories?.premium
                : item?.electricalAccessories?.isOpted,
              item?.nonElectricalAccessories?.isOpted,
              item?.nonElectricalAccessories?.isOpted
                ? item?.nonElectricalAccessories?.premium
                : item?.nonElectricalAccessories?.isOpted,
              item?.invoiceCover?.isOpted,
              item?.invoiceCover?.isOpted
                ? item?.invoiceCover?.premium
                : item?.invoiceCover?.isOpted,
              item?.ncbProtection?.isOpted,
              item?.ncbProtection?.isOpted
                ? item?.ncbProtection?.premium
                : item?.ncbProtection?.isOpted,
              item?.voluntaryDeductive?.isOpted,
              item?.voluntaryDeductive?.isOpted
                ? item?.voluntaryDeductive?.premium
                : item?.voluntaryDeductive?.isOpted,
              item?.passengerCover?.isOpted,
              item?.passengerCover?.isOpted
                ? item?.passengerCover?.premium
                : item?.passengerCover?.isOpted,
              item?.paCoverAmount?.isOpted,
              item?.paCoverAmount?.isOpted
                ? item?.paCoverAmount?.premium
                : item?.paCoverAmount?.isOpted,
              item?.lossOfPersonalBelongings?.isOpted,
              item?.lossOfPersonalBelongings?.isOpted
                ? item?.lossOfPersonalBelongings?.premium
                : item?.lossOfPersonalBelongings?.isOpted,
              item?.zeroDepriciationCover?.isOpted,
              item?.zeroDepriciationCover?.isOpted
                ? item?.zeroDepriciationCover?.premium
                : item?.zeroDepriciationCover?.isOpted,
              item?.keyReplacement?.isOpted,
              item?.keyReplacement?.isOpted
                ? item?.keyReplacement?.premium
                : item?.keyReplacement?.isOpted,
              item?.ncbDiscountAmount,
              item?.biFuelOd?.isOpted,
              item?.biFuelOd?.isOpted
                ? item?.biFuelOd?.premium
                : item?.biFuelOd?.isOpted,
              item?.biFuelTp?.isOpted,
              item?.biFuelTp?.isOpted
                ? item?.biFuelTp?.premium
                : item?.biFuelTp?.isOpted,
              item?.llToPaidDriver?.isOpted,
              item?.llToPaidDriver?.isOpted
                ? item?.llToPaidDriver?.premium
                : item?.llToPaidDriver?.isOpted,
              item?.caseId,
              item?.insurerId,
              item?.kycAdditionalData,
              item?.planId,
              item?.tppdDiscount ? item?.tppdDiscount : false,
              item?.loadingPremium ? item?.loadingPremium : false,
              '',
              '',
              item?.odTenure ? item?.odTenure : 0 ,
              item?.tpTenure ? item?.tpTenure :  0,
              item?.ncbDiscountPercentage ? ncbDiscountPercentage : 0,
            )
          )
      )
    );
  };

  const handleUpdateMMV = () => {
    // debugger;
    console.log("NEW FILTER", newFilter);
    const currentDate = new Date();
    const regDate = new Date(apiRequestQQ?.RegistrationDate);
    const difference = Math.abs(currentDate - regDate);
    const diffInYears = (difference / (1000 * 60 * 60 * 24 * 365.25)).toFixed(
      3
    );
    console.log("difference", difference);
    console.log("difference", diffInYears);
    dispatchQuickQuote("differenceInYears", diffInYears);
    let newData = { ...apiRequestQQ };
    let newData2 = { ...motorRequest}

    for (let key in newFilter) {
      newData[key] = newFilter[key];
      dispatchQuickQuote(`${key}`, newFilter[key]);
    }
    console.log("NEW DATA", newData);
    console.log("YEAR DIFF", yearDiff);
    if (apiRequestQQ.VehicleType === "4w" && yearDiff < 3) {
      dispatchQuickQuote("NewPolicyType", "ODOnly");
      newData = { ...newData, NewPolicyType: "ODOnly" };
    } else if (apiRequestQQ.VehicleType === "4w" && yearDiff >= 3) {
      dispatchQuickQuote("NewPolicyType", "Comprehensive");
      newData = { ...newData, NewPolicyType: "Comprehensive" };
    }
    if (apiRequestQQ.VehicleType === "2w" && yearDiff < 5) {
      dispatchQuickQuote("NewPolicyType", "ODOnly");
      newData = { ...newData, NewPolicyType: "ODOnly" };
    } else if (apiRequestQQ.VehicleType === "2w" && yearDiff >= 5) {
      dispatchQuickQuote("NewPolicyType", "Comprehensive");
      newData = { ...newData, NewPolicyType: "Comprehensive" };
    }

    console.log("API REQUEST QQ", apiRequestQQ);

    // for (let key in newFilter) {
    //   if (
    //     key == "Digit" ||
    //     key == "Shriram" ||
    //     key == "HDFC" ||
    //     key == "Bajaj"
    //   ) {
    //     dispatchQuickQuote("VariantCode." + key, newFilter[key]);
    //   } else {
    //     dispatchQuickQuote(key, newFilter[key]);
    //   }
    // }
    dispatch(resetQuickQuoteResults());
    // digitAPICall(newData);
    // KotakAPICall(newData);
    // getZunoQuickQuote(newData);
    // motorQuotationObj(motorRequest)
    // HDFCCalculatePremium(newData);
    // RelianceAPICall(newData);
    // bajajApiCall(newData);
    // FutureGeneralQuickQuote(newData);
    // magmaAPICall(newData);
    motorQuotation(newData2)
    setModal(!show);
  };

  const handleMfgDate = (val) => {
    console.log("HANDLE MFG", val);
    let date = new Date(val);
    date.setDate(1);
    date.setMonth(0);

    const manufacturingDate = date.toISOString().slice(0, 10);
    // dispatchQuickQuote("ManufaturingDate", val);
    setNewFilter((prevState) => ({
      ...prevState,
      ManufaturingDate: manufacturingDate,
      ManufacturingYear: val,
    }));
  };
  const handleRegDate = (val) => {
    console.log("HANDLE REG", val);
    const regYear = new Date(val).getFullYear();
    console.log("regYear", regYear);
    setNewFilter((prevState) => ({
      ...prevState,
      RegistrationDate: val,
      RegistrationYear: regYear,
    }));
    // dispatchQuickQuote("RegistrationYear", regYear);
    // dispatchQuickQuote("RegistrationDate", val);
  };

  return (
    <Modal
      isOpen={show}
      toggle={toggle}
      size="lg"
      backdrop="static"
      keyboard={false}
      className="modal-dialog-centered"
    >
      <ModalBody>
        <div className="modal-logo">
          <a href="">
            <img src="/assets/images/ex_new_2.png"></img>
          </a>
        </div>
        <div className="modal_heading w-100 mb-3">
          <h4 className="text-center fs-1 mb-4">Edit Vehicle Details</h4>

          <div onClick={toggle} class="v2_close false">
            <a href="">
              <img
                src="/assets/images/delete_icon.svg"
                title="Close"
                alt="close"
              />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 ps-lg-3 mt-2">
            <div className="form-group">
              <label className="fs-4">Manufacture</label>

              <div className="">
                <ReactSelect
                  value={makeData.filter(
                    (option) => option.label === newFilter.MakeName
                  )}
                  options={makeData}
                  name="make"
                  placeholder="Select Manufacturer"
                  onChange={(val) => handleSelectMake(val.value)}
                  defaultValue={
                    newFilter?.MakeName
                      ? newFilter?.MakeName
                      : apiRequestQQ?.MakeName
                  }
                />{" "}
              </div>
            </div>
          </div>
          <div className="col-lg-6 pe-lg-3 mt-2">
            <div className="form-group">
              <label className="fs-4">Model</label>
              <div className="">
                <ReactSelect
                  options={modelData}
                  name="model"
                  value={modelData.filter(
                    (option) => option.label === newFilter.ModelName
                  )}
                  placeholder="Select vehicle modal"
                  onChange={(val) => handleSelectModel(val.value)}
                  defaultValue={
                    newFilter?.ModelName
                      ? newFilter?.ModelName
                      : apiRequestQQ?.ModelName
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 pe-lg-3 mt-2 re-2">
            <div className="form-group">
              <label className="fs-4"> Variant</label>
              <div className="">
                <ReactSelect
                  value={variantData.filter(
                    (option) => option.label === newFilter.VariantName
                  )}
                  options={variantData}
                  name="variant"
                  placeholder="Select vehicle varient"
                  onChange={(val) => handleSelectVariant(val)}
                  defaultValue={
                    newFilter?.VariantName
                      ? newFilter?.VariantName
                      : apiRequestQQ?.VariantName
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 ps-lg-3 mt-2">
            <div className="form-group">
              <label className="fs-4"> Fuel Type</label>
              <select
                className="form-control"
                defaultValue={newFilter.FuelType}
                onChange={(e) => handleSelectPetrolType(e.target.value)}
              >
                <option>Select Fuel Type</option>
                {fuelTypes.includes("Petrol") && (
                  <option
                    value="Petrol"
                    selected={
                      apiRequestQQ?.FuelType === "Petrol" ? true : false
                    }
                  >
                    Petrol
                  </option>
                )}
                {fuelTypes.includes("Diesel") && (
                  <option
                    value="Diesel"
                    selected={
                      apiRequestQQ?.FuelType === "Diesel" ? true : false
                    }
                  >
                    Diesel
                  </option>
                )}
                {fuelTypes.includes("Electric") && (
                  <option
                    value="Battery"
                    selected={
                      apiRequestQQ?.FuelType === "Battery" ? true : false
                    }
                  >
                    Electric
                  </option>
                )}
                {fuelTypes.includes("CNG") && (
                  <option
                    value="CNG"
                    selected={apiRequestQQ?.FuelType === "CNG" ? true : false}
                  >
                    CNG
                  </option>
                )}
                {fuelTypes.includes("LPG") && (
                  <option
                    value="LPG"
                    selected={apiRequestQQ?.FuelType === "LPG" ? true : false}
                  >
                    LPG
                  </option>
                )}
              </select>
            </div>
          </div>

          <div className="col-lg-6 ps-le-0 mt-2">
            <div className="form-group">
              <label className="fs-4">Manufacture Year</label>
              <input
                className="form-control"
                defaultValue={apiRequestQQ.ManufaturingDate}
                type="month"
                value={newFilter.manufacturingDate}
                onChange={(e) => handleSelectManufacturingType(e.target.value)}
                // value={manufacturingDate}
              />{" "}
            </div>
          </div>
          <div className="col-lg-6 pe-lg-3 mt-2">
            <div className="form-group">
              <label className="fs-4">Registration Date </label>
              <input
                // defaultValue={apiRequestQQ.RegistrationDate}
                defaultValue={
                  newFilter.RegistrationDate
                    ? newFilter.RegistrationDate
                    : apiRequestQQ.RegistrationDate
                }
                onChange={(e) => handleRegDate(e.target.value)}
                min={newFilter?.ManufaturingDate}
                className="form-control"
                type="date"
                // value={newFilter.registrationDate}
                // value={registrationDate}
              />
            </div>
          </div>
          <div className="col-lg-12 ps-le-0 mt-4 text-center">
            <button
              type="button"
              onClick={() => handleUpdateMMV()}
              className="btn"
            >
              Update
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EditVehicleDetails;
// login wali className
