import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactSelect from "../../common/Tags/ReactSelect";
import { useForm } from "react-hook-form";
import {
  dispatchQuickQuote,
  selectedPlanAction,
  dispatchMotorQuote
} from "../../../store/actions/userActions";
import SelectList from "../../common/Tags/SelectList";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  FutureGeneralQuickQuote,
  kotakIssueApi,
  LibertyFullQuote,
  RoyalSundramGenerateProposal,
  ShriramGeneratPolicy,
  ZunoFullQuote,
  magmaProposalCall,
  submitProposal,
  getKyc,
  zunoBillDesk
} from "../Homepage/TPApiCall";
import moment from "moment";
import { GetDataWithToken, PostDataWithToken } from "../../../api/apiHelper";
import { sendErrorInfo } from "../../Services/PosService";
import ShriramKYCForm from "../../pages/Additional/ShriramKYCForm";
import { createQuotePayloadObj } from "../../../store/reducers/userReducers";
import { toast } from "react-toastify";
const VehicleDetails = ({
  rtoData,
  activeTab,
  toggle,
  insurerData,
  financierData,
  cityData,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    watch
  } = useForm({ mode: "onBlur" });
  const [RegistrationNumber, setRegistrationNumner] = useState("");
  const apiRequestQQ = useSelector((state) => state.root.apiRequestQQ);
  const [disabled, setDisabled] = useState("");
  const selectedPlan = useSelector((state) => state.root.selectedPlan);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Hpn, setHpn] = useState(false);
  const [submitProposalError, setSubmitProposalError] = useState({});
  const [isValidPUC, setIsValidPUC] = useState(false);
  const [rtoCode, setRtoCode] = useState("");
  const [relianceRto, setRelianceRto] = useState([]);
  const [selectedRelianceRTO, setSelRelianceRTO] = useState("");
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);
  const formRef = useRef(null);
  const [ProposalSpinner, setProposalSpinner] = useState(false)
  const engineNumber = watch("EngineNumber");
  const chassisNumber = watch("ChassisNumber");
  const [hideBtn, setHideBtn] = useState(false);
  const motorRequest = useSelector((state) => state.root.motorRequest);
  const kycConfigVal = useSelector((state) => state.root.kycConfig);




  useEffect(() => {
    setDisabled("");
    reset({
      RegistrationNumber: apiRequestQQ.RegistrationNumber,
      PrePolicyNumber: apiRequestQQ.PrePolicyNumber,
      PrePolicyEndDate: apiRequestQQ.PrePolicyEndDate,
      PreviousInsuranceClaimed: apiRequestQQ.PreviousInsuranceClaimed,
      PreInsurerCode: apiRequestQQ.PreInsurerCode,
      PreviousInsurancePolicyNumber: apiRequestQQ.PreviousInsurancePolicyNumber,
    });
    setRegistrationNumner(apiRequestQQ.RegistrationNumber);
    let rtoNumber = apiRequestQQ.RegistrationNumber.split("-");
    if (apiRequestQQ.IsVehicleNew) {
      setRtoCode(apiRequestQQ.RtoCode);
    } else {
      setRtoCode(rtoNumber[0] + rtoNumber[1]);
    }
    let RTOCode = apiRequestQQ.RtoCode
      ? apiRequestQQ.RtoCode
      : rtoNumber[0] + rtoNumber[1];
    GetDataWithToken("motor/getRelianceRto", "").then((response) => {
      if (response.status === true) {
        let i = 0;
        let arr = [];
        while (i < response.data.length) {
          let item = response.data[i];
          if (
            item.Region_Code ==
            RTOCode.slice(0, 2) + "-" + RTOCode.slice(2, 4)
          ) {
            arr.push({
              value: item.Model_Region_ID_PK,
              label: item.Region_Code + " " + item.Region_Name,
            });
          }
          i++;
        }
        setRelianceRto(arr);
      }
    });
  }, []);

  useEffect(() => {
    if (activeTab == 3 && selectedPlan.Api_name === "Shriram") {
      const { FatherName, MotherName, AadharNo, PassportPic, AadharBackPic } =
        apiRequestQQ.KYC;

      if (
        FatherName == "" ||
        MotherName == "" ||
        AadharNo == "" ||
        PassportPic == "" ||
        AadharBackPic == ""
      ) {
        setDisabled("");
        setModal(true);
      }
    }
  }, [activeTab]);

  useEffect(() => {
    if (selectedPlan.Api_name == "Reliance" && relianceRto.length > 0) {
      setSelRelianceRTO(relianceRto[0].value);
      dispatchQuickQuote("RelianceRTOCode", relianceRto[0].value);
    }
  }, [relianceRto]);

  const selectRtoCity = (val) => {
    dispatchQuickQuote("RtoCode", val.value);
  };

  const areValuesSame = () => {
    return engineNumber && chassisNumber && engineNumber === chassisNumber;
  };

  const submitVehicleDetails = async (data) => {

    // debugger

    console.log('dataaaa',data)

    dispatchMotorQuote('reasonForNotOptingPersonalAccident', data.reason)

    if (data.EngineNumber === data.ChassisNumber) {
      return;
    }

    if (selectedPlan.Api_name == "Reliance" && isValidPUC == false) {
      return sendErrorInfo("Please select valid PUC");
    }
    dispatchQuickQuote("RegistrationNumber", RegistrationNumber);
    for (let key in data) {
      dispatchQuickQuote(key, data[key]);
    }
    console.log("apiRequestQQ proposal", apiRequestQQ);
    const newData = { ...apiRequestQQ, ...data,PolicyIssueDate : new Date().toLocaleDateString('en-GB').toString() };
    console.log("apiRequestQQapiRequestQQapiRequestQQ",newData);
    setDisabled("disabled");
    if (selectedPlan.Api_name === "Shriram") {
      const { FatherName, MotherName, AadharNo, PassportPic, AadharBackPic } =
        apiRequestQQ.KYC;

      if (
        FatherName == "" ||
        MotherName == "" ||
        AadharNo == "" ||
        PassportPic == "" ||
        AadharBackPic == ""
      ) {
        setDisabled("");
        setModal(true);
      } else {
        setDisabled("disabled");
        generateShriramPolicy(newData);
      }
    } else if (selectedPlan.Api_name === "Kotak") {
      // alert("kotak clicked");

      kotakIssueApi(newData)
        .then((response) => {
          console.log("responseeee", response);
          if (response.status === true) {
            if (
              response.data.Fn_Save_Partner_Private_Car_ProposalResult
                .vErrorMessage === "Success"
            ) {
              // alert("kotak success");
              let data = { ...selectedPlan };
              data.vProposalNumber =
                response.data.Fn_Save_Partner_Private_Car_ProposalResult.vProposalNumber;
              data.vProductCode =
                response.data.Fn_Save_Partner_Private_Car_ProposalResult.vProductCode;
              data.vPolicyNumber =
                response.data.Fn_Save_Partner_Private_Car_ProposalResult.vPolicyNumber;
              dispatch(selectedPlanAction(data));
              navigate("/policy-overview");
            } else {
              alert("Something went wrong, Try Again");
              // navigate("/");
              setDisabled("");
            }
          } else {
            setDisabled("");
          }
        })
        .catch((err) => {
          console.log(err);
          setDisabled("");
        });
    }else if (selectedPlan.Api_name === "Magma"){
      setProposalSpinner(true)
      setHideBtn(true)
      const magmaData = {...newData, KYCID: selectedPlan?.KYCID, KYCNumber: selectedPlan?.KYCNumber, KYCType: selectedPlan?.KYCType}
      console.log("apiRequestQQapiRequestQQapiRequestQQ magmaaa",magmaData);
      magmaProposalCall(magmaData).then((response) => {
        console.log("response in magma proposal frntend", response)
        if(response?.data?.ServiceResult === "Success"){
          // debugger
          setProposalSpinner(false)
          setHideBtn(false)
          dispatchQuickQuote('ProposalId', response?.data?.OutputResult?.ProposalNumber)
          dispatchQuickQuote('CustomerID',response?.data?.OutputResult?.CustomerID)
          dispatchQuickQuote('FinalPremium', response?.data?.OutputResult?.PremiumBreakUp?.TotalPremium)
          
          dispatchQuickQuote('IncorporationDate',response?.data?.IncorporationDate)
          // const newData = {...selectedPlan, CustomerID: response?.data?.OutputResult?.CustomerID, FinalPremium: response?.data?.OutputResult?.PremiumBreakUp?.TotalPremium}
          // dispatch(selectedPlanAction(newData));
          navigate('/policy-overview')
        }else if(response?.data?.ServiceResult === "Failure"){
          setProposalSpinner(false)
          setHideBtn(false)
          if (response.data.ErrorText.includes("This Registration No is already used")) {
            toast.error('This Registration No is already used');
        }}
        else{
          // setDisabled("");
          alert(Status.message)
        }
      })
    }

    else if(selectedPlan.Api_name === 'Tata AIG General Insurance Co. Ltd.' || "ICICI Lombard General Insurance Co. Ltd." || "Bajaj Allianz General Insurance Co. Ltd." || 'HDFC' || 'Zurich Kotak General Insurance Company (India) Limited' || "Future Generali India Insurance Company Limited"){

      setProposalSpinner(true)
      setHideBtn(true)

      // debugger


      console.log("submitVehicleDetails", data);
      console.log('motooooo',motorRequest);
      let newData = { ...motorRequest, customerType : apiRequestQQ.CustomerType === 'INDIVIDUAL' ? 'individual' : 'organisation' ,  engineNumber : data.EngineNumber , chassisNumber :data.ChassisNumber ,prevPolicyNumber : data.PrePolicyNumber , customerType : apiRequestQQ.CustomerType === 'INDIVIDUAL' ? 'individual' : 'organisation' , prevTPInsurerCode : data.PreTPInsurerCode ? Number(data.PreTPInsurerCode) : '' ,prevTPPolicyNumber: data.PreTPPolicyNumber ? data.PreTPPolicyNumber : '' , prevTPPolicyEndDate : data.PreTPPolicyEndDate ? data.PreTPPolicyEndDate : '', reasonForNotOptingPersonalAccident : data.reason, isPreviousInsuranceClaimed : data.PreviousInsuranceClaimed ? JSON.parse(data.PreviousInsuranceClaimed) : '' , sessionId :'', posId : motorRequest?.posId ? motorRequest?.posId  : '' };

       
      console.log('hhhhhhhh',newData)

      // for (let key in data) {
      //   dispatchMotorQuote(`${key}`, data[key]);
      //   newData = { ...newData, [key]: data[key] };
      // }

      // dispatchMotorQuote('engineNumber',data.EngineNumber)
      // dispatchMotorQuote('chassisNumber',data.ChassisNumber)
      // dispatchMotorQuote('prevPolicyNumber',data.PrePolicyNumber)
      // dispatchMotorQuote('customerType',apiRequestQQ.CustomerType === 'INDIVIDUAL' ? 'individual' : 'organisation')
      

      // console.log('llllllll',llllll)

      // if (selectedBank !== "") {
      //   newData["hpnBank"] = selectedBank;
      // }

      console.log("proposal request data", newData);

      const response = await submitProposal(newData, selectedPlan);
      console.log("response proposal", response);
      if(selectedPlan.Api_name === 'ICICI Lombard General Insurance Co. Ltd.' ){

        if (response?.data?.otherData.isQuoteDeviation || response?.data?.otherData.breakingFlag || response?.data?.otherData?.isApprovalRequired  ) {

          response?.data?.otherData.isQuoteDeviation && toast.error('Ex Showroom Price is not Correct')

          response?.data?.otherData.breakingFlag && toast.error('Please Create Break-In ID')

          response?.data?.otherData?.isApprovalRequired && toast.error('Proposal Went Into Under Writer Approval')

        }
        else{
          if (response?.data?.caseStatus === "Proposal Successful") {
            // dispatchMotorQuote("paymentUrl", response?.data?.paymentUrl);
            toast.success("Proposal Successful", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
    
            if(kycConfigVal?.kycConfig?.kycStep === "after_proposal"){
    
              const respon  = await getKyc(data, selectedPlan,motorRequest);
              console.log('response kyc' , respon )
      
              if(respon.data.kycStatus === 'success'){
      
              dispatchMotorQuote('kycStatus','success')
      
              toast.success('Kyc Successfull')
      
              const response2 = await submitProposal(newData, selectedPlan)
      
              if(response2?.data?.caseStatus === "Proposal Successful"){
      
                navigate(`/policy-overview`);}
      
              }
              else if(respon.data.kycStatus === "failure" ){
      
                dispatchMotorQuote('kycStatus','failure')
      
                // debugger
      
               toggle(activeTab - 2)
      
               toast.error('Kyc Fail')
      
              }
              
            }
            else{
              setProposalSpinner(false)
              setHideBtn(false)
              navigate("/policy-overview");
            }
    
          }
          else{
    
            // debugger
    
            setProposalSpinner(false)
            setHideBtn(false)
            toast.error('Proposal Failure')
    
          }
        }
      }
      else{
      if (response?.data?.caseStatus === "Proposal Successful") {
        if(selectedPlan.Api_name === 'Bajaj Allianz General Insurance Co. Ltd.'){
        dispatchMotorQuote("paymentUrl", response?.data?.paymentUrl);}
        toast.success("Proposal Successful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        if(kycConfigVal?.kycConfig?.kycStep === "after_proposal"){

          const respon  = await getKyc(data, selectedPlan,motorRequest);
          console.log('response kyc' , respon )
  
          if(respon.data.kycStatus === 'success'){
  
          dispatchMotorQuote('kycStatus','success')
  
          toast.success('Kyc Successfull')
  
          const response2 = await submitProposal(newData, selectedPlan)
  
          if(response2?.data?.caseStatus === "Proposal Successful"){
  
            navigate(`/policy-overview`);}
  
          }
          else if(respon.data.kycStatus === "failure" ){
  
            dispatchMotorQuote('kycStatus','failure')
  
            // debugger
  
           toggle(activeTab - 2)
  
           toast.error('Kyc Fail')
  
          }
          
        }
        else{
          setProposalSpinner(false)
          setHideBtn(false)
          navigate("/policy-overview");
        }

      }
      else{

        // debugger

        setProposalSpinner(false)
        setHideBtn(false)
        toast.error('Proposal Failure')

      }}

    }
    
    else if (selectedPlan.Api_name === "Royal") {
      RoyalSundramGenerateProposal(newData)
        .then((response) => {
          if (response.status === true) {
            const { PREMIUMDETAILS, proposalResult } = response.data;
            const { Status, DATA } = PREMIUMDETAILS;
            if (Status.StatusCode === "S-0005") {
              console.log(PREMIUMDETAILS);
              setDisabled("");
              navigate("/policy-overview");
            } else {
              setDisabled("");
              alert(Status.Message);
            }
          } else {
            setDisabled("");
          }
        })
        .catch((err) => {
          console.log(err);
          setDisabled("");
        });
    } else if (selectedPlan.Api_name == "HDFC") {
      HDFCCreateProposal(newData);
    } else if (selectedPlan.Api_name == "Zuno") {
      setProposalSpinner(true)
      setHideBtn(true)
      ZunoFullQuote(newData).then((response) => {
        setDisabled("");
        if (response.status === true) {
          setProposalSpinner(false)
          setHideBtn(false)
          dispatchQuickQuote("zunoPremium" , response.data.premiumDetails.grossTotalPremium)
          dispatchQuickQuote("quoteNo",response.data.policyLevelDetails.quoteNo)
          dispatchQuickQuote("quoteOptionNo",response.data.policyLevelDetails.quoteOptionNo)
          dispatchQuickQuote("bpId",response.data.policyLevelDetails.policyHolder.businessPartnerId)
          dispatchQuickQuote("zunoUrl", response.billDeskUrl)

          const contractDetails = response.data.contractDetails;
          const grossTotalPremium = response.data.premiumDetails.grossTotalPremium;
          // alert(
          //   parseFloat(
          //     contractDetails[0].contractPremium.contractPremiumAfterTax
          //   ) +
          //     parseFloat(
          //       contractDetails[1].contractPremium.contractPremiumAfterTax
          //     ) || 0
          // );
          // debugger

          navigate("/policy-overview");
        }
        else{
          setProposalSpinner(false)
          setHideBtn(false)
          }
      });
    } else if (selectedPlan.Api_name == "Liberty") {
      LibertyFullQuote(newData).then((response) => {
        if (response.status === true) {
          if (response.data.ErrorText) {
            alert(response.data.ErrorText);
            setDisabled("");
          } else {
            let data = { ...selectedPlan };
            data.ApiId = response.data.QuotationNumber;
            data.PolicyId = response.data.QuotationNumber;
            data.CustomerID = response.data.CustomerID;

            dispatch(selectedPlanAction(data));
            navigate("/policy-overview");
          }

          console.log(response.data);
        }
      });
    } else {
      navigate("/policy-overview");
    }
  };

  const generateShriramPolicy = (newData) => {
    ShriramGeneratPolicy(newData).then((response) => {
      if (response.status === true) {
        setDisabled("");
        const { GenerateProposalResult } = response.data;
        if (
          GenerateProposalResult &&
          GenerateProposalResult.ERROR_CODE == "0"
        ) {
          dispatchQuickQuote("ApiId", GenerateProposalResult.POL_SYS_ID);
          dispatchQuickQuote("policyId", GenerateProposalResult.PROPOSAL_NO);
          navigate("/policy-overview");
        } else {
          alert(response.data.MessageResult.ErrorMessage);
        }
      } else {
        setDisabled("");
      }
    });
  };

  const setRegistrationValue = (val) => {
    let number = "";
    let stateCode = val.substring(3, 5);
    let rtoCode = val.substring(6, 8);
    let plateNumber = val.substring(9, 13);
    if (val.length <= 2) {
      if (val.match(/^[A-Za-z]+$/)) {
        number = val;
        if (val.length === 2) {
          number = number + "-";
        }
      } else {
        number = "";
      }
    } else if (val.length > 3 && val.length <= 5) {
      if (stateCode.match(/^[0-9]+$/)) {
        if (val.length > 3 && val.length < 5) {
          number = RegistrationNumber + stateCode;
        }
        if (val.length === 5) {
          number = RegistrationNumber + val.charAt(4) + "-";
        }
      } else {
        stateCode = "";
        number = RegistrationNumber + stateCode;
      }
    }
    if (val.length > 6 && val.length <= 8) {
      console.log("rto code", rtoCode);
      if (rtoCode.match(/^[A-Za-z]+$/)) {
        number = val;
        if (val.length === 8) {
          number = number + "-";
        }
      } else {
        number = "";
      }
    } else if (val.length > 9 && val.length <= 14) {
      if (plateNumber.match(/^[0-9]+$/)) {
        number = val;
      }
    }
    setRegistrationNumner(number);
  };

  const changeHpnState = (e) => {
    const { checked, value } = e.target;
    dispatchQuickQuote("IsHypothecation", checked);
    setHpn(checked);
  };

  const changePUCState = (e) => {
    const { checked, value } = e.target;
    dispatchQuickQuote("IsValidPuc", checked);
    setIsValidPUC(checked);
  };

  const handleSetHpnBank = (val) => {
    // console.log("hpn-----------", val);
    dispatchQuickQuote("HpnBank", val.value);
    dispatchQuickQuote("HpnHDFCID", val.HDFCId);
  };

  const handleSetHpnCity = (val) => {
    // console.log("hpn city", val);
    dispatchQuickQuote("HpnCity", val.City);
  };

  const handleSelectPincode = (val) => {
    console.log("value", val);
    dispatchQuickQuote("RelianceRTOCode", val.value);
    setSelRelianceRTO(val.value);
  };

  const HDFCCreateProposal = (newData) => {
    setProposalSpinner(true)
    PostDataWithToken(
      "motor/hdfcCreateProposal",
      createQuotePayloadObj(newData)
    )
      .then((response) => {
        if (response.status === true) {
          setDisabled("");
          console.log("response data", response.data);
          if (response.data.StatusCode == 200) {
            setProposalSpinner(false)
            dispatchQuickQuote(
              "ProposalId",
              response.data.Policy_Details.ProposalNumber
            );
            navigate("/policy-overview");
          } else {
            setDisabled("");
            alert("Something went wrong, Please try again");
          }
          // setHDFC({
          //   Policy_Number: response.data.Policy_Details.PolicyNumber,
          //   TransactionID: response.data.TransactionID,
          // });
        } else {
          setDisabled("");
          alert("Something went wrong, Please try again");
        }
      })
      .catch((err) => console.log(err));
  };

  console.log("rtoDData", rtoData);
  console.log("rtocode", rtoCode);
  // setTimeout(() => {
  //   console.log(
  //     "FILTER",
  //     rtoData.length > 0 &&
  //       rtoData?.filter((options) => options.value === rtoCode)
  //   );
  // }, 3000);

  // if (rtoData.length === 0) {
  //   return;
  // } else {
  //   const filteredData = rtoData?.filter(
  //     (options) => options.value === rtoCode
  //   );
  //   console.log("filteredData", filteredData);
  // }

  return (
    <div className="">
      <form ref={formRef} onSubmit={handleSubmit(submitVehicleDetails)}>
        {/* <p className="mb-0 fs-1 fw-bold">Vehicle Details</p> */}
        <div className="posformbox">
          <div className="">
            {apiRequestQQ.IsVehicleNew === false && (
              <div className="">
                <div className="position-relative mb-5">
                  <div className="form-floating ">
                    <input
                      type="text"
                      {...register("RegistrationNumber")}
                      value={RegistrationNumber}
                      onChange={(e) => setRegistrationValue(e.target.value)}
                      className="form-control fs-3"
                      id="fsa12"
                      placeholder=" Enter Registration Number"
                      disabled={true}
                    />
                    <label htmlFor="fsa12">
                      Enter Registration Number
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                </div>
              </div>
            )}
            {selectedPlan.Api_name != "Reliance" && rtoCode != "" && (
              <div className="">
                <div className="position-relative mb-5">
                  <div className="form-floating fs-3">
                    <input
                      type="text"
                      value={`${rtoCode} - ${apiRequestQQ?.RegistrationCity}`}
                      className="form-control fs-3"
                      id="fsa12"
                      placeholder="RTO Location"
                      disabled={true}
                    />
                    <label htmlFor="fsa12">
                      RTO Location
                      <span className="text-danger">*</span>
                    </label>
                    {/* {rtoData && (
                      <ReactSelect
                        options={rtoData}
                        placeholder="Select RTO Location"
                        onChange={(val) => selectRtoCity(val)}
                        value={rtoCode}
                      />
                    )} */}
                  </div>
                </div>
              </div>
            )}
            {selectedPlan.Api_name == "Reliance" && (
              <div className="">
                <div className="position-relative mb-5">
                  <div className="form-floating">
                    <ReactSelect
                      options={relianceRto}
                      onChange={(val) => handleSelectPincode(val)}
                      placeholder="Select RTO Location"
                      value={relianceRto.filter(
                        (option) => option.value === selectedRelianceRTO
                      )}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="">
              <div className="position-relative mb-5 ">
                <div className="form-floating ">
                  <input
                    type="text"
                    maxLength={selectedPlan.Api_name === "digit" ? 20 : 30}
                    {...register("EngineNumber", {
                      required: "Engine Number is required",
                      pattern: {
                        value: /^[A-Za-z0-9]+$/i,
                        message: "Invalid value",
                      },

                      minLength: {
                        value:apiRequestQQ.IsVehicleNew === true
                                            ? 6
                                            : 6,
                        message:
                          apiRequestQQ.IsVehicleNew === true
                            ? "Value must be minumum 6 number"
                            : "Value must be minumum 6 number",
                      },
                    })}
                    className="form-control fs-3 text-uppercase"
                    id="fsa112"
                    placeholder="Enter Engine No."
                  />
                  <label htmlFor="fsa112">
                    Engine No
                    <span className="text-danger">*</span>
                  </label>

                  <p className="f-error fs-4 mx-2">
                    {errors?.EngineNumber?.message}
                  </p>
                </div>
              </div>
            </div>

            <div className="">
              <div className="position-relative mb-5">
                <div className="form-floating ">
                  <input
                    type="text"
                    maxLength={selectedPlan.Api_name === "HDFC" ? 17  : 25}
                    {...register("ChassisNumber", {
                      required: "Chassis Number is required",
                      pattern: {
                        value: /^[a-z0-9]+$/i,
                        message: "Invalid value",
                      },
                      minLength: {
                        value:selectedPlan.Api_name === "HDFC" ? 17 :  apiRequestQQ.IsVehicleNew === true ? 17 : 6,
                        message:
                        selectedPlan.Api_name === "HDFC" ? "Value must be between 17 number" : 
                          apiRequestQQ.IsVehicleNew === true
                            ? "Value must be between 17 to 25 number"
                            : "Value must be between 6 to 25 number",
                      },
                    })}
                    className="form-control fs-3 text-uppercase"
                    id="fsa112"
                    placeholder="Enter Chassis No."
                  />
                  <label htmlFor="fsa112">
                    Chassis No.
                    <span className="text-danger">*</span>
                  </label>

                  <p className="f-error fs-4 mx-2">
                    {errors?.ChassisNumber?.message}
                  </p>
                </div>
              </div>
            </div>
            {areValuesSame() && (
        <p className="fs-4" style={{color: "red"}}>Engine Number and Chassis Number cannot be the same.</p>
      )}
            {apiRequestQQ.IsVehicleNew === false && (
              <>
                <div className="">
                  <div className="position-relative mb-5">
                    <div className="form-floating ">
                      <input
                        type="text"
                        onInput={(e) => {
                          if (e.target.value > 24) {
                            e.target.value = e.target.value.slice(0, 25);
                          }
                        }}
                        maxLength={25}
                        {...register("PrePolicyNumber", {
                          required: "Previous Policy Number is required",
                        })}
                        className="form-control fs-3"
                        id="fsaIO"
                        placeholder="Enter Previous Year Policy Number"
                      />
                      <label htmlFor="fsaIO">
                        Previous Policy Number
                        <span className="text-danger">*</span>
                      </label>

                      <p className="f-error fs-4 mx-2">
                        {errors?.PrePolicyNumber?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
            {console.log('motor',motorRequest)}
            {selectedPlan.Api_name === "Tata AIG General Insurance Co. Ltd."  ?( (motorRequest.personalAccident == false && motorRequest.newPolicyType !== 'odOnly') && (<div className=' ps-lg-0'>
              <div className='position-relative mb-5'>
                <div className='form-floating'>
                  <select 
                        {...register('reason', { required: 'Please select a reason.' })}
                          className='form-select fs-3' id='casetype1as' >
                    <option className='d-none' value=''>
                      Select Reason For Not Opting Personal Accident
                    </option>
                    <option value='1'>Owner driver does not hold valid driving license</option>
                    <option value='2'>Insured has standalone PA cover equals to or greater than 15 lakhs</option>
                  </select>
                </div>
                {errors.reason && <p className="f-error fs-4 mx-2">{errors.reason.message}</p>}
              </div>
            </div>)) : null}
            {apiRequestQQ.NewPolicyType === "ODOnly" &&
              selectedPlan.Api_name != "Kotak" && (
                <>
                  <div className="">
                    <div className="position-relative mb-5">
                      <div className="form-floating ">
                        <input
                          type="text"
                          {...register("PreTPPolicyNumber")}
                          className="form-control fs-3"
                          id="fsaIO"
                          placeholder="Enter Previous Year Policy Number"
                        />
                        <label htmlFor="fsaIO">
                          Previous TP Policy Number
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className=" pe-lg-0">
                    <div className="position-relative mb-5">
                      <div className={"form-floating position-relative mb-4"}>
                        <select
                          name={"PreInsurerCode"}
                          className="form-select fs-3"
                          id={"s12lio"}
                          {...register("PreTPInsurerCode")}
                        >
                          <option value="">Select</option>
                          {insurerData.map((item, index) => {
                            const excludedInsurers = [
                              "Tata AIG General Insurance Co. Ltd.",
                              "ICICI Lombard General Insurance Co. Ltd.",
                              "HDFC",
                              "Zurich Kotak General Insurance Company (India) Limited",
                              "Bajaj Allianz General Insurance Co. Ltd.",
                              "Future Generali India Insurance Company Limited"
                            ];

                            const isExcluded = excludedInsurers.includes(selectedPlan.Api_name);

                            return (
                              <option key={index} value={isExcluded ? item.insurerId : item.value}>
                                {item.option}
                              </option>
                            );
                          })}
                        </select>

                        {/*----- Input Label -----*/}
                        <label htmlFor={"s12lio"} className="d-block w-100">
                          Previous TP Policy Provider
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="position-relative mb-5">
                      <div className="form-floating ">
                        <input
                          type="date"
                          {...register("PreTPPolicyEndDate")}
                          className="form-control fs-3"
                          id="fsaIO"
                          placeholder="Enter Previous Year Policy Number"
                          min={apiRequestQQ.RegistrationDate}
                          max={moment(
                            apiRequestQQ.RegistrationDate,
                            "YYYY-MM-DD"
                          )
                            .add(5, "year")
                            .format("YYYY-MM-DD")}
                        />
                        <label htmlFor="fsaIO">
                          Previous TP End Date
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </>
              )}

            <div className="row mb-5">
              <div className="col-3">
                <div className="declare">
                  <label className="declarebox fs-3" htmlFor="hpn">
                    Hypothecation?
                    <input
                      type="checkbox"
                      className="form-check-input"
                      style={{ marginRight: "0.5rem" }}
                      id="hpn"
                      onChange={(e) => changeHpnState(e)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
              <div className="col-5">
                {apiRequestQQ.IsVehicleNew === false ? <div className="declare">
                  <label className="declarebox fs-3" htmlFor="PUCVALID">
                    Is Valid PUC Available?
                    <input
                    // {...register("PUCVALID", {
                    //       required: "PUC Number is required",
                    //     })}
                      type="checkbox"
                      name="PUCVALID"
                      className="form-check-input"
                      style={{ marginRight: "0.5rem" }}
                      id="PUCVALID"
                      onChange={(e) => changePUCState(e)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  {/* {errors.PUCVALID ? (
                    <p className="f-error fs-4 mx-2">{errors.PUCVALID.message}</p>
                    ) : ""} */}
                </div> :

                 <div className="declare">
                  <label className="declarebox fs-3" htmlFor="PUCVALID">
                    Is Valid PUC Available?
                    <input
                      className="form-check-input"
                      style={{ marginRight: "0.5rem" }}
                      id="PUCVALID"
                      onChange={(e) => changePUCState(e)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>}
              </div>
            </div>
            {Hpn === true && (
              <>
                <div className=" ">
                  <div className="position-relative mb-5">
                    <div className=" ">
                      <ReactSelect
                        options={financierData}
                        onChange={(val) => handleSetHpnBank(val)}
                        placeholder="Select finance bank"
                      />
                    </div>
                  </div>
                </div>
                <div className=" ">
                  <div className="position-relative mb-5">
                    <div className="">
                      <ReactSelect
                        options={cityData}
                        onChange={(val) => handleSetHpnCity(val)}
                        placeholder="Select finance city"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {isValidPUC === true && (
              <>
                <div className="">
                  <div className="position-relative mb-5">
                    <div className="form-floating ">
                      <input
                        type="text"
                        {...register("PUCNumber", {
                          required: "Field is required",
                        })}
                        className="form-control fs-3"
                        id="fsaIO"
                        placeholder="Enter PUC Number"
                      />
                      <label htmlFor="fsaIO">
                        PUC Number
                        <span className="text-danger">*</span>
                      </label>
                      <p className="f-error fs-4 mx-2">
                        {errors?.PUCNumber?.message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="position-relative mb-5">
                    <div className="form-floating ">
                      <input
                        type="date"
                        {...register("PUCValidUpto", {
                          required: "Field is required",
                        })}
                        className="form-control fs-3"
                        id="fsaIO"
                        min={moment().format("YYYY-MM-DD")}
                      />
                      <label htmlFor="fsaIO">
                        Puc Valid Upto
                        <span className="text-danger">*</span>
                      </label>
                      <p className="f-error fs-4 mx-2">
                        {errors?.PUCValidUpto?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}


            <div className="d-flex ms-5">

            <div className="col-lg-5">

            {selectedPlan.Api_name === "Magma" ?  (apiRequestQQ.AddOns.PersonalAccident !== true ? (apiRequestQQ.docType === 'ckyc' || apiRequestQQ.docType === 'pan' ? <button disabled={hideBtn}  style={{ filter: hideBtn ? 'blur(2px)' : 'none' }} onClick={() => toggle(activeTab - 2)} className="btn btn-primary mb-5 mt-3 py-3 w-100 fs-1 fw-bold">Previous</button> : 
            <button disabled={hideBtn}  style={{ filter: hideBtn ? 'blur(2px)' : 'none' }} onClick={() => toggle(activeTab - 1)} className="btn btn-primary mb-5 mt-3 py-3 w-100 fs-1 fw-bold">Previous</button>) : <button disabled={hideBtn}  style={{ filter: hideBtn ? 'blur(2px)' : 'none' }} onClick={() => toggle(activeTab - 1)} className="btn btn-primary mb-5 mt-3 py-3 w-100 fs-1 fw-bold">Previous</button> ) :  
            <button disabled={hideBtn}  style={{ filter: hideBtn ? 'blur(2px)' : 'none' }} onClick={() => toggle(activeTab - 1)} className="btn btn-primary mb-5 mt-3 py-3 w-100 fs-1 fw-bold">Previous</button>}

              {/* <button onClick={() => toggle(activeTab - 1)} className="btn btn-primary mb-5 mt-3 py-3 w-100 fs-1 fw-bold">Previous</button> */}

            </div>

            <div className="col-lg-5 ms-5" >
            <div className="">
              <button disabled={hideBtn}  style={{ filter: hideBtn ? 'blur(2px)' : 'none' }}
                type="submit"
                // disabled={disabled}
                className="btn btn-primary mb-5 mt-3 py-3 w-100 fs-1 fw-bold"
              >
                {ProposalSpinner ? <div class="spinner-border text-light" role="status">
               <span class="sr-only"></span>
                </div>: 'Submit'}
              </button>
            </div>
            </div>
            </div>
          </div>
        </div>
      </form>
      <ShriramKYCForm
        modal={modal}
        setModal={(val) => setModal(val)}
        toggleModal={toggleModal}
      />
    </div>
  );
};

export default VehicleDetails;
